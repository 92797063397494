import gql from "graphql-tag";
import { SETTINGS_FRAGMENT } from "../settingsFragment";

export const SETTINGS_QUERY = gql`
  query settings {
    settings {
      ...Settings
    }
  }
  ${SETTINGS_FRAGMENT}
`;
