import React from 'react'
import AntdTable from '../../../component/elements'
import { Row, Col } from 'antd';

interface Props {

}

const ErrorLog = (props: Props) => {

    const dataSource = [
        {
            key: '1',
            file_name: 'mailchimp.ts',
            error: "TypeError: \'set\' on proxy: trap returned falsish for property\'offset\'",
            details: 'Issue with agenda getAllMailChimpList',
        },
        {
            key: '2',
            file_name: 'mailchimp.ts',
            error: "TypeError: 'set' on proxy: trap returned falsish for property 'offset'",
            details: 'Issue with agenda getAllMailChimpList',
        },
    ];

    const columns = [
        {
            title: 'File name',
            dataIndex: 'file_name',
            key: 'file_name',
        },
        {
            title: 'Error',
            dataIndex: 'error',
            key: 'error',
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
        },
    ];

    return (
        <Row style={{ marginTop: '30px' }}>
            <Col span={22} offset={1}>
                <AntdTable rowKey='key' pagination={false} dataSource={dataSource} columns={columns} />
            </Col>
        </Row>
    )
}

export default ErrorLog
