import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragmet';

export const STOP_LIVE_STREAM_MUTATION = gql`
  mutation stopLiveStream($_id: ID, $vid: String) {
    stopLiveStream(_id: $_id, vid: $vid) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
