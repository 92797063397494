import React, { useEffect, useState } from 'react';
import LoadingSpinner from 'component/Loader';
import { Row, Col } from 'antd';
import { Line } from 'react-chartjs-2';
import VideoReportTable from 'component/VideoReportTable/VideoReportTable';
import ViewersLineChart from 'component/ViewersLineChart/ViewersLineChart';
import MinutesPlayedLineChart from 'component/MinutesPlayedLineChart/MinutesPlayedLineChart';

const AnalyticsContainer: React.FC<any> = () => {
  return (
    <div>
      <Row style={{ marginTop: '15px' }}>
        <Col span={22} offset={1}>
          <VideoReportTable />
        </Col>
      </Row>
      <Row style={{ marginTop: '30px', backgroundColor: 'white' }}>
        <Col span={22} offset={1}>
          <ViewersLineChart />
        </Col>
      </Row>
      <Row style={{ marginTop: '30px', backgroundColor: 'white' }}>
        <Col span={22} offset={1}>
          <MinutesPlayedLineChart />
        </Col>
      </Row>
    </div>
  );
};

export default AnalyticsContainer;
