import { ApolloClient, ApolloLink, InMemoryCache } from "apollo-boost";
import { RetryLink } from "apollo-link-retry";
// import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import ApolloLinkTimeout from "apollo-link-timeout";
// import { persistCache } from 'apollo-cache-persist';
import { createUploadLink } from "apollo-upload-client";
import QueueLink from "apollo-link-queue";
import { getMainDefinition } from "apollo-utilities";
// import { defaults } from "apollo/defaults";
// import resolvers from "apollo/resolvers";
import { getToken, removeToken } from "helper/utils";
import config from "config";
import logger from "helper/logger";
// import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
// import introspectionQueryResultData from "generated/fragmentTypes.json";

// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// });
const cache = new InMemoryCache({
  // @ts-ignore
  dataIdFromObject: (o) => `${o.__typename}: ${o._id || o.id}`,
  //   fragmentMatcher,
});

const authLink = setContext(async (req, { headers }) => {
  const token = await getToken();
  return {
    ...headers,
    headers: { authorization: token ? `Bearer ${token}` : "" },
  };
});

const timeoutLink = new ApolloLinkTimeout(15000);
// @ts-ignore
const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    console.error("NETWORK_ERROR", networkError); // eslint-disable-line
    // return forward(operation);
  }
  if (graphQLErrors) {
    graphQLErrors.map(async (error) => {
      const { extensions, message } = error;
      // eslint-disable-next-line no-console
      console.log("extensions:", extensions);
      // eslint-disable-line
      logger(`APOLLO_ERROR: message -> ${message}, more ->`, error); // eslint-disable-line
      if (extensions && extensions.code === "INVALID_TOKEN") {
        if (window.location.pathname !== "/auth/login") {
          await removeToken();
          window.location.href = `${window.location.origin}/auth/login`;
        }
      }
      return null;
    });
  }
  return null;
});

const offlineLink = new QueueLink();
window.addEventListener("offline", () => offlineLink.close());
window.addEventListener("online", () => offlineLink.open());
// persistCache({
//   cache,
//   storage: window.sessionStorage,
//   key: 'drbot_persist_cache',
//   maxSize: false
// });

// const token = getToken();
// const wsLink = new WebSocketLink({
//   uri: config.GRAPHQL_SUBSCRIPTION_ENDPOINT,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       authToken: token,
//     },
//   },
// });

const uploadLink = createUploadLink({ uri: config.GRAPHQL_ENDPOINT });

const link = new RetryLink().split(
  // split based on operation type
  ({ query }) => {
    const { kind } = getMainDefinition(query);
    return kind === "OperationDefinition";
  },
  //   wsLink,
  uploadLink
);

const apolloClient = new ApolloClient({
  link: authLink.concat(
    ApolloLink.from([errorLink, offlineLink, link, timeoutLink])
  ),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});

// cache.writeData({
//   data: defaults,
// });

export default apolloClient;
