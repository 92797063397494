import React, { useState } from 'react';
import { Row, Col, Button, message } from 'antd';
import AntdTable from 'component/elements';
import { ME_QUERY } from 'apollo/user/queries';
import { useQuery } from '@apollo/react-hooks';
import { getToken } from 'helper';
import config from 'config';
import axios from 'axios';

interface UserContainerProps { };

const tasks = [
    {
        id: 1,
        name: 'getAndSaveEvents'
    },
    {
        id: 2,
        name: 'getAndSaveClosures'
    },
    {
        id: 3,
        name: 'getAndSaveNotices'
    },
    {
        id: 4,
        name: 'getAndSaveCancellations'
    },
    {
        id: 5,
        name: 'getAndSaveNews'
    },
    {
        id: 6,
        name: 'getAndSaveEzSchedules'
    },
    {
        id: 7,
        name: 'removeGhostSchedules'
    },
]

const SingleTaskButton = ({ record }) => {
    const [loading, setLoading] = useState(false);

    const handleRunTask = async (name) => {
        // axios request
        if (!name) {
            throw new Error("Something went wrong.")
        }
        // const localUrl = 'http://192.168.1.70:5000/graphql'
        const url = config.GRAPHQL_ENDPOINT;
        try {
            setLoading(true);
            const data = {
                operationName: 'manualTask',
                variables: {
                    name: name,
                    type: 'function',
                    channel: 'TPASC_MANUAL_TASK'
                },
                query: `mutation manualTask($name:ManualTaskNames!, $type:ManualTaskTypes!,$channel:Channel!) {
            manualTask(name:$name, type:$type,channel:$channel)
          }`
            };

            const headers = {
                accept: '*/*',
                'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
                authorization: '',
                'content-type': 'application/json',
            };

            const config = {
                method: 'post',
                url: url,
                headers: headers,
                data: JSON.stringify(data),
                // withCredentials: true
            };
            const res = await axios(config);
            setLoading(false);
            message.success({ content: `Action ${name} run Successfully`, key: name, duration: 2 });
            console.log("the response is-----", res);
        } catch (err) {
            setLoading(false);
            console.log("error", err)
            message.error({ content: `Failed to run ${name}`, key: name, duration: 2 });
        }
    }
    return (
        <Button type="primary" loading={loading} onClick={() => handleRunTask(record?.name)}>
            Run
        </Button>
    )
}

const ManualTaskContainer: React.FC<UserContainerProps> = ({ }) => {
    const [tableLoading, setTableLoading] = useState(false);
    const columns = [
        {
            key: '#',
            title: '#',
            render: record => <span>{record.id}</span>,
            // sorter: (a, b) => a.firstName.length - b.firstName.length,
            // sortDirections: ['descend', 'ascend'],
        },
        {
            key: 'name',
            title: 'Name',
            render: record => <span>{record.name}</span>,
            // sorter: (a, b) => a.lastName.length - b.lastName.length,
            // sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record, index) => (
                <>
                    <span style={{ marginRight: 10 }}>
                        <SingleTaskButton record={record} />
                    </span>
                </>
            ),
        },
    ];
    return (
        <>
            <Row style={{ marginTop: '30px' }}>
                <Col span={22} offset={1}>
                    <AntdTable loading={tableLoading} pagination={false} rowKey={record => record._id} columns={columns} dataSource={tasks} />
                </Col>
            </Row>
        </>
    );
};
export default ManualTaskContainer;
