import { Layout as AntdLayout } from 'antd';
import styled from 'styled-components';

export const StyledSidebar = styled(AntdLayout.Sider)`
    position: relative;
    overflow-y: auto;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    z-index: 998;
    background: #202541;
    & .ant-menu-dark {
        background: transparent;
    }
    & .ant-menu.ant-menu-dark .ant-menu-sub {
        background-color: transparent;
        box-shadow: none;
    }
    & .ant-menu.ant-menu-dark .ant-menu-item {
        /* margin: 0; */
        padding-left: 50px;
        padding-right: 0;
    }
    & .ant-menu.ant-menu-dark .ant-menu-item-selected {
        background: rgb(38, 45, 78);
    }
`;

export const StyledSidebarHeader = styled(AntdLayout.Header)`
    position: relative;
    background: transparent;
    height: auto;
    margin-bottom: 30px;
    & h1 {
        font-size: 2rem;
        line-height: 1.8;
        color: #f7f7f7;
        margin: 0;
    }
`;