import { gql } from "apollo-boost";

export const USER_FRAGMENT = gql`
  fragment User on User {
    _id
    email
    firstName
    lastName
    role
    avatarUrl
    enabledRoutes
    createdAt
    lastSessionAt
    disabled
  }
`;
