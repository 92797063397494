import React, { useEffect, useState } from 'react';
import { Button, notification, Radio } from 'antd';

const StreamComp = ({ onStart, onEnd, setStreaming }) => {
  const [streamId, setStreamId] = useState(new Date().getTime().toString());
  const [streamOn, setStreamOn] = useState(false);
  const [videoId, setVideoId] = useState<null | String>(null);
  const [showVideo, setShowVideo] = useState(true);

  useEffect(() => {
    return () => {
      console.log('here close');
      // (window as any).webRTCAdaptor.switchVideoCameraCapture(streamId);
      (window as any).webRTCAdaptor.turnOffLocalCamera();
      (window as any).webRTCAdaptor.closeStream(streamId);
      (window as any).webRTCAdaptor.stop(streamId);
      setShowVideo(false);
      setStreaming(false);
      setStreamOn(false);
      if (streamOn) {
        onEnd();
      }
    };
  }, []);

  const switchMode = (chbx) => {
    // var camera_checkbox = document.getElementById('camera_checkbox');
    // var screen_share_checkbox = document.getElementById('screen_share_checkbox');
    // var screen_share_with_camera_checkbox = document.getElementById('screen_share_with_camera_checkbox');
    // if (camera_checkbox == chbx) {
    //   (window as any).webRTCAdaptor.switchVideoCapture(streamId);
    // } else if (screen_share_checkbox == chbx) {
    //   (window as any).webRTCAdaptor.switchDesktopCapture(streamId);
    // } else if (screen_share_with_camera_checkbox == chbx) {
    //   (window as any).webRTCAdaptor.switchDesktopCaptureWithCamera(streamId);
    // } else {
    //   chbx.checked = true;
    // }
    console.log('chbx', chbx.target.value);
    if (chbx.target.value == 'camera') (window as any).webRTCAdaptor.switchVideoCameraCapture(streamId);
    if (chbx.target.value == 'screen') (window as any).webRTCAdaptor.switchDesktopCapture(streamId);
    if (chbx.target.value == 'cameraScreen') (window as any).webRTCAdaptor.switchDesktopCaptureWithCamera(streamId);
  };
  const initWebRTCAdaptor = (publishImmediately, autoRepublishEnabled) => {
    const token = 'null';
    // const streamId = streamIdala";
    const websocketURL = 'wss://antvideo.tpasc.ca:5443/LiveApp/websocket?rtmpForward=undefined';
    const pc_config = null;
    const sdpConstraints = {
      OfferToReceiveAudio: false,
      OfferToReceiveVideo: false,
    };
    const checkAndRepublishIfRequired = () => {
      var iceState = (window as any).webRTCAdaptor.signallingState((window as any).streamId);
      if (iceState == null || iceState == 'failed' || iceState == 'disconnected') {
        console.log('Publish has stopped and will try to re-publish');
        (window as any).webRTCAdaptor.stop((window as any).streamId);
        (window as any).webRTCAdaptor.closePeerConnection((window as any).streamId);
        (window as any).webRTCAdaptor.closeWebSocket();
        initWebRTCAdaptor(true, autoRepublishEnabled);
      }
    };
    (window as any).webRTCAdaptor = new (window as any).WebRTCAdaptor({
      websocket_url: websocketURL,
      mediaConstraints: (window as any).mediaConstraints,
      peerconnection_config: pc_config,
      sdp_constraints: sdpConstraints,
      localVideoId: 'localVideo',
      debug: true,
      bandwidth: 900,
      callback: function (info, obj) {
        if (info == 'initialized') {
          console.log('initialized');
          // start_publish_button.disabled = false;
          // stop_publish_button.disabled = true;
          if (publishImmediately) {
            (window as any).webRTCAdaptor.publish((window as any).streamId, token);
          }
        } else if (info == 'publish_started') {
          //stream is being published
          setStreaming(true);
          setStreamOn(true);
          console.log('publish started info>>>>>>>>>>', info);
          console.log('publish started obj>>>>>>>>>>', obj);
          notification.success({
            message: 'Stream Started',
            description: 'Streaming started successfully.',
            duration: 0,
          });
          // start_publish_button.disabled = true;
          // stop_publish_button.disabled = false;
          // startAnimation();
          if (autoRepublishEnabled && (window as any).autoRepublishIntervalJob == null) {
            (window as any).autoRepublishIntervalJob = setInterval(() => {
              checkAndRepublishIfRequired();
            }, 3000);
          }
        } else if (info == 'publish_finished') {
          //stream is being finished
          console.log('publish finished');
          setStreamOn(false);
          setStreaming(false);
          // start_publish_button.disabled = false;
          // stop_publish_button.disabled = true;
        } else if (info == 'browser_screen_share_supported') {
          // camera_checkbox.disabled = false;
          // screen_share_checkbox.disabled = false;
          // screen_share_with_camera_checkbox.disabled = false;
          console.log('browser screen share supported');
          // browser_screen_share_doesnt_support.style.display = "none";
        } else if (info == 'screen_share_stopped') {
          // camera_checkbox.checked = true;
          // screen_share_checkbox.checked = false;
          // screen_share_with_camera_checkbox.checked = false;
          console.log('screen share stopped');
        } else if (info == 'closed') {
          //console.log("Connection closed");
          if (typeof obj != 'undefined') {
            console.log('Connecton closed: ' + JSON.stringify(obj));
          }
        } else if (info == 'pong') {
          //ping/pong message are sent to and received from server to make the connection alive all the time
          //It's especially useful when load balancer or firewalls close the websocket connection due to inactivity
        } else if (info == 'refreshConnection') {
          checkAndRepublishIfRequired();
        } else if (info == 'ice_connection_state_changed') {
          console.log('iceConnectionState Changed: ', JSON.stringify(obj));
        } else if (info == 'updated_stats') {
          //obj is the PeerStats which has fields
          //averageOutgoingBitrate - kbits/sec
          //currentOutgoingBitrate - kbits/sec
          console.log(
            'Average outgoing bitrate ' +
              obj.averageOutgoingBitrate +
              ' kbits/sec' +
              ' Current outgoing bitrate: ' +
              obj.currentOutgoingBitrate +
              ' kbits/sec'
          );
        } else if (info == 'data_received') {
          console.log('Data received: ' + obj.event.data + ' type: ' + obj.event.type + ' for stream: ' + obj.streamId);
          // $("#dataMessagesTextarea").append(
          //   "Received: " + obj.event.data + "\r\n"
          // );
        } else {
          console.log(info + ' notification received');
        }
      },
      callbackError: function (error, message) {
        //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError

        console.log('error callback: ' + JSON.stringify(error));
        var errorMessage = JSON.stringify(error);
        if (typeof message != 'undefined') {
          errorMessage = message;
        }
        var errorMessage = JSON.stringify(error);
        if (error.indexOf('NotFoundError') != -1) {
          errorMessage = 'Camera or Mic are not found or not allowed in your device';
        } else if (error.indexOf('NotReadableError') != -1 || error.indexOf('TrackStartError') != -1) {
          errorMessage = 'Camera or Mic is being used by some other process that does not let read the devices';
        } else if (error.indexOf('OverconstrainedError') != -1 || error.indexOf('ConstraintNotSatisfiedError') != -1) {
          errorMessage = 'There is no device found that fits your video and audio constraints. You may change video and audio constraints';
        } else if (error.indexOf('NotAllowedError') != -1 || error.indexOf('PermissionDeniedError') != -1) {
          errorMessage = 'You are not allowed to access camera and mic.';
        } else if (error.indexOf('TypeError') != -1) {
          errorMessage = 'Video/Audio is required';
        } else if (error.indexOf('ScreenSharePermissionDenied') != -1) {
          errorMessage = 'You are not allowed to access screen share';
          // camera_checkbox.checked = true;
          // screen_share_checkbox.checked = false;
          // screen_share_with_camera_checkbox.checked = false;
        } else if (error.indexOf('WebSocketNotConnected') != -1) {
          errorMessage = 'WebSocket Connection is disconnected.';
        }
        alert(errorMessage);
      },
    });
  };
  useEffect(() => {
    initWebRTCAdaptor(false, true);
    return () => {};
  }, []);

  return (
    <div style={{ width: '100%' }}>
      {showVideo && (
        <video
          id="localVideo"
          // autoplay={true}
          autoPlay={true}
          muted={true}
          controls={true}
          style={{ width: '100%' }}
          // playsinline=""
        ></video>
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {!streamOn ? (
          <Button
            type="primary"
            onClick={() => {
              // startPublishing
              onStart(streamId).then((data) => {
                console.log('create ', data);
                setVideoId(data.data.createVideo._id);
                (window as any).streamId = streamId;
                (window as any).webRTCAdaptor.publish((window as any).streamId, 'null');
              });
            }}
            id="start_publish_button"
          >
            Start Live
          </Button>
        ) : (
          <Button
            type="danger"
            onClick={() => {
              // stopPublishing;
              // (window as any).streamId = streamId;

              if ((window as any).autoRepublishIntervalJob != null) {
                clearInterval((window as any).autoRepublishIntervalJob);
                (window as any).autoRepublishIntervalJob = null;
              }
              (window as any).webRTCAdaptor.stop((window as any).streamId);
              onEnd();
            }}
            className="btn btn-primary"
            // disabled=""
            id="stop_publish_button"
          >
            Stop Publishing
          </Button>
        )}
        <Radio.Group defaultValue="camera" buttonStyle="solid" onChange={switchMode} style={{ marginLeft: 15 }}>
          <Radio.Button value="camera">Camera</Radio.Button>
          <Radio.Button value="screen">Screen</Radio.Button>
          <Radio.Button value="cameraScreen">Screen And Camera</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

export default StreamComp;
