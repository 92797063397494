import React, { useState } from 'react';
import { Switch, Divider } from 'antd';

const RouteSwitch = ({ onChange, checked, view }) => {
  const onChangeChecked = (val) => {
    onChange(val, view);
  };
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{view.title}</span>
        <Switch checkedChildren="on" unCheckedChildren="off" checked={checked} onChange={onChangeChecked} />
      </div>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
    </>
  );
};

export default RouteSwitch;
