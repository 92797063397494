import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from '../videoFragmet';

export const VIDEOS_QUERY = gql`
  query videos {
    videos {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
