import { gql } from "apollo-boost";
import { GATES_FRAGMENT } from "../gatesFragment";

export const GATE_UPDATE_MUTATION = gql`
  mutation updateGate($id: ID!, $input: GateUpdateInput!) {
    updateGate(_id: $id, input: $input) {
      ...Gate
    }
  }
  ${GATES_FRAGMENT}
`;
