import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import {
  Row,
  Col,
  Button,
  Icon,
  Modal,
  Form,
  Input,
  InputNumber,
  message,
  AutoComplete,
  Switch,
} from "antd";
import { WrappedFormUtils, FormComponentProps } from "antd/lib/form/Form";
import { GATES_QUERY } from "apollo/gates/queries";
import {
  GATE_CREATE_MUTATION,
  GATE_UPDATE_MUTATION,
} from "apollo/gates/mutations";

interface NewFormProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
  gates: any[];
}

const AddEditModalComponent: React.FC<NewFormProps> = ({
  form,
  isCreate,
  currentItem,
  handleCancel,
  handleOk,
  modalVisible,
  gates,
}) => {
  const [createGate] = useMutation(GATE_CREATE_MUTATION, {
    update(cache, { data: { createGate } }) {
      const cachedData: any = cache.readQuery({ query: GATES_QUERY });
      cache.writeQuery({
        query: GATES_QUERY,
        data: { gates: [...cachedData.gates, createGate] },
      });
    },
  });
  const [updateGate] = useMutation(GATE_UPDATE_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const labelSource = [
    {
      label: "light",
      color: "green",
    },
    {
      label: "moderate",
      color: "orange",
    },
    {
      label: "busy",
      color: "red",
    },
  ];
  const [gatesParam, setGatesParam] = useState({
    name: "",
    code: "",
    currentLoad: 0,
    currentLoadLocked: false,
    active: false,
    threshold: [] as any,
  });

  useEffect(() => {
    if (currentItem) {
      const threshold = currentItem.threshold.map(function (item) {
        return {
          color: item.color,
          label: item.label,
          max: item.max,
          min: item.min,
        };
      });
      setGatesParam({
        ...gatesParam,
        name: currentItem.name,
        code: currentItem.code,
        currentLoad: currentItem.currentLoad,
        currentLoadLocked: currentItem.currentLoadLocked,
        active: currentItem.active,
        threshold: threshold,
      });
    }
  }, [currentItem]);

  const addNewThreshold = () => {
    setGatesParam({
      ...gatesParam,
      threshold: [
        ...gatesParam.threshold,
        {
          label: "",
          color: "",
          max: 0,
          min: 0,
        },
      ],
    });
  };
  const { getFieldDecorator } = form;

  const handleInputChange = (val, ind, type) => {
    const { threshold } = gatesParam;
    threshold[ind][type] = val;
    setGatesParam({
      ...gatesParam,
      threshold,
    });
  };

  const removeThreshold = (index) => {
    const { threshold } = gatesParam;
    threshold.splice(index, 1);
    setGatesParam({
      ...gatesParam,
      threshold,
    });
  };

  const onThresholdLabelSelect = (value, index) => {
    const definedColor = labelSource.filter(
      (singleLabel) => singleLabel.label === value
    )[0]["color"];
    const colorChange = {};
    colorChange[`threshold-color_${index}`] = definedColor;
    form.setFieldsValue({
      ...colorChange,
    });
    handleInputChange(value, index, "label");
    handleInputChange(definedColor, index, "color");
  };

  const resetForm = () => {
    form.resetFields();
    setGatesParam({
      name: "",
      code: "",
      currentLoad: 0,
      currentLoadLocked: false,
      active: false,
      threshold: [] as any,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        ...gatesParam,
        name: values.name,
        code: values.code,
        currentLoad: values.currentLoad,
        currentLoadLocked: values.currentLoadLocked,
        active: values.active,
      };
      if (isCreate) {
        createGate({
          variables: {
            input: {
              ...params,
            },
          },
        })
          .then((mutationResult) => {
            setIsLoading(false);
            resetForm();
            handleOk();
          })
          .catch((e) => {
            setIsLoading(false);
            const errorMessage = e
              .toString()
              .replace("Error: GraphQL error:", "");
            message.error(errorMessage);
          });
      } else {
        if (values.currentLoad == currentItem.currentLoad) {
          delete params.currentLoad;
        }
        updateGate({
          variables: {
            id: currentItem._id,
            input: {
              ...params,
            },
          },
        })
          .then((updatedResult) => {
            setIsLoading(false);
            resetForm();
            handleOk();
          })
          .catch((e) => {
            setIsLoading(false);
            const errorMessage = e
              .toString()
              .replace("Error: GraphQL error:", "");
            message.error(errorMessage);
          });
      }
    });
  };
  return (
    <Modal
      title={isCreate ? "Add New" : "Update Detail"}
      footer={null}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator("name", {
            initialValue: gatesParam.name,
            rules: [
              {
                required: true,
                message: "Please input the name of site!",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Site Code">
          {getFieldDecorator("code", {
            initialValue: gatesParam.code,
            rules: [
              {
                required: true,
                message: "Please input the code of site!",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Current Load">
          {getFieldDecorator("currentLoad", {
            initialValue: gatesParam.currentLoad,
            rules: [
              {
                required: true,
                message: "Please input the currentLoad of site!",
              },
            ],
          })(<InputNumber style={{ width: "100%" }} />)}
        </Form.Item>
        <Form.Item label="Current Load Locked">
          {getFieldDecorator("currentLoadLocked", {
            valuePropName: "checked",
            initialValue: gatesParam.currentLoadLocked,
          })(<Switch />)}
        </Form.Item>
        <Form.Item label="Active">
          {getFieldDecorator("active", {
            valuePropName: "checked",
            initialValue: gatesParam.active,
          })(<Switch />)}
        </Form.Item>
        {gatesParam.threshold.map((item: any, index) => (
          <Row key={index}>
            <Col span={6} offset={1}>
              <Form.Item label="Label">
                {getFieldDecorator(`threshold-label_${index}`, {
                  initialValue: item.label,
                  rules: [{ required: true, message: "Label cannot be empty" }],
                })(
                  <AutoComplete
                    dataSource={labelSource.map(
                      (singleLabel) => singleLabel.label
                    )}
                    onSelect={(value) => {
                      onThresholdLabelSelect(value, index);
                    }}
                    // onSearch={handleSearch}
                  >
                    <Input
                      type="textarea"
                      onChange={(e) =>
                        handleInputChange(e.target.value, index, "label")
                      }
                    />
                  </AutoComplete>
                )}
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label="Color">
                {getFieldDecorator(`threshold-color_${index}`, {
                  initialValue: item.color,
                  rules: [{ required: true, message: "Color cannot be empty" }],
                })(
                  <Input
                    type="textarea"
                    onChange={(e) =>
                      handleInputChange(e.target.value, index, "color")
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item label="Value">
                {getFieldDecorator(`threshold-value_${index}`, {
                  initialValue: item.min,
                  rules: [
                    {
                      required: true,
                      message: "Threshold value cannot be empty",
                    },
                  ],
                })(
                  <InputNumber
                    onChange={(number) =>
                      handleInputChange(number, index, "min")
                    }
                    style={{ marginRight: 10 }}
                  />
                )}
                {index === gatesParam.threshold.length - 1 ? (
                  <Icon
                    className="dynamic-delete-button"
                    type="minus-circle-o"
                    style={{ fontSize: 18 }}
                    onClick={() => removeThreshold(index)}
                  />
                ) : null}
              </Form.Item>
            </Col>
          </Row>
        ))}
        <Row>
          <Col span={24}>
            <Button
              type="dashed"
              onClick={addNewThreshold}
              style={{ width: "100%" }}
            >
              <Icon type="plus" /> Add Threshold Detail
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "30px" }}>
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>
            {isCreate ? "Add" : "Update"}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
const AddEditModal = Form.create<NewFormProps>()(AddEditModalComponent);
export default AddEditModal;
