import { gql } from 'apollo-boost';

export const SAVE_VG_WEBAPP_SETTINGS_MUTATION = gql`
  mutation saveVgWebappSettings($input: VgWebappSettingsInput!) {
    saveVgWebappSettings(input: $input) {
      _id
      trainerHubButton {
        _id
        link
        label
        color
      }
    }
  }
`;
