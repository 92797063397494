import React, { useState } from 'react';
import TrainerHubButtonTable from 'component/TrainerHubButtonTable/TrainerHubButtonTable';
import { Card, Row, Col, Button, notification, List, Typography } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { VG_WEBAPP_SETTINGS_QUERY } from 'apollo/vgWebappSettings/queries';
import LoadingSpinner from 'component/Loader';
import { SAVE_VG_WEBAPP_SETTINGS_MUTATION } from 'apollo/vgWebappSettings/mutations';
import { TrainerHubDefaultButtons } from 'helper/trainerHubDefault';

function VgAppSettingsContainer() {
  const [isLoading, setIsLoading] = useState(false);
  const [settingsParam, setSettingsParam] = useState<any>({
    _id: null,
    trainerHubButton: [],
  });

  const [saveVgWebappSettingsMutation] = useMutation(SAVE_VG_WEBAPP_SETTINGS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: ['vgWebappSettings'],
  });

  const { loading } = useQuery(VG_WEBAPP_SETTINGS_QUERY, {
    onCompleted: data => {
      setSettingsParam(prev => ({
        ...prev,
        trainerHubButton: data?.vgWebappSettings?.trainerHubButton || [],
        _id: data?.vgWebappSettings?._id || null,
      }));
    },
  });

  const onAddTrainerHubButton = trainerHubButton => {
    setSettingsParam(prev => ({ ...prev, trainerHubButton: [...prev.trainerHubButton, trainerHubButton] }));
  };
  const onEditTrainerHubButton = (trainerHubButton, index) => {
    const temp = [...settingsParam.trainerHubButton];
    temp[index] = trainerHubButton;
    setSettingsParam(prev => ({ ...prev, trainerHubButton: [...temp] }));
  };
  const onDeleteTrainerHubButton = (trainerHubButton, index) => {
    const temp = [...settingsParam.trainerHubButton];
    temp.splice(index, 1);
    setSettingsParam(prev => ({ ...prev, trainerHubButton: [...temp] }));
  };

  const saveSettings = () => {
    setIsLoading(true);
    const input = {
      _id: settingsParam._id,
      trainerHubButton: settingsParam.trainerHubButton.map(item => ({
        label: item.label,
        link: item.link,
        color: item.color,
      })),
    };
    saveVgWebappSettingsMutation({
      variables: {
        input,
      },
    })
      .then(mutationRes => {
        setIsLoading(false);
        setSettingsParam(prev => ({ ...prev, _id: mutationRes?.data?.saveVgWebappSettings?._id }));
        notification.success({ message: 'Updated Succesfully.', duration: 0 });
      })
      .catch(err => {
        setIsLoading(false);
        const errorMessage = err.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Organization Update Failed',
          duration: 0,
          description: errorMessage,
        });
      });
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Row style={{ marginTop: 15, marginBottom: 15 }}>
          <Col span={11} offset={1} style={{ background: '#FFF', paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Card style={{ marginBottom: '24px' }} title="Trainer Hub Buttons">
              <TrainerHubButtonTable
                trainerHubButtons={settingsParam.trainerHubButton}
                onAdd={onAddTrainerHubButton}
                onEdit={onEditTrainerHubButton}
                onDelete={onDeleteTrainerHubButton}
              />
            </Card>
            <div style={{ marginBottom: '24px', textAlign: 'right' }}>
              <Button type="primary" onClick={saveSettings} loading={isLoading}>
                Save
              </Button>
            </div>
          </Col>
          <Col span={10} offset={1} style={{ background: '#FFF', paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Card style={{ marginBottom: '24px' }} title="Trainer Hub Default Button">
              <div>
                <List
                  size="small"
                  bordered
                  dataSource={TrainerHubDefaultButtons}
                  renderItem={item => (
                    <List.Item>
                      <span>
                        Label: {item.label}
                        <br />
                        Link: {item.link}
                        <br />
                        Color: {item.color}
                        <br />
                      </span>
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default VgAppSettingsContainer;
