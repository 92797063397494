import { gql } from 'apollo-boost';

export const FLAGGED_BOOKINGS_FRAGMENT = gql`
  fragment BookingFlag on BookingFlag {
    _id
    ClientID
    ClientName
    ClientEmail
    title
    month
    bookingStatus
    firstBookingDate
    firstFlaggedMailSent
    firstFlaggedMailSentDate
    secondBookingDate
    secondFlaggedMailSent
    secondFlaggedMailSentDate
    isSelfServiceBlocked
    totalFlagged
    remarks
    createdAt
    updatedAt
  }
`;
