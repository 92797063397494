import { gql } from 'apollo-boost';

export const VG_WEBAPP_SETTINGS_QUERY = gql`
  query vgWebappSettings {
    vgWebappSettings {
      _id
      trainerHubButton {
        _id
        link
        label
        color
      }
    }
  }
`;
