import React, { useState } from 'react';
import { Row, Col, Button, Tooltip, Dropdown, Icon, Menu, Switch, Modal, message } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import LoadingSpinner from 'component/Loader';
import AntdTable from 'component/elements';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import AddEditUserModal from 'component/AddEditUserModal/AddEditUserModal';
import { ME_QUERY, USERS_QUERY } from 'apollo/user/queries';
import EditUserAccessModal from 'component/EditUserAccessModal/EditUserAccessModal';
import ChangePwModal from 'component/ChangePwModal/ChangePwModal';
import styled from 'styled-components';
import { USER_DELETE_MUTATION, USER_UPDATE_MUTATION } from 'apollo/user/mutations';

interface UserContainerProps { }


const Param = styled.div`
text-align: center;
font-size: 29px;
margin-bottom: 0;
cursor: pointer;
`;
const StyledMenu = styled(Menu)`
.ant-dropdown-menu-item {
  padding: 15px !important;
  direction: ${({ theme: locale }) => (locale === "ar" ? "rtl" : "ltr")};

  > span {
    font-size: 14px;
    letter-spacing: 0.8px;
    color: #555;

    i {
      margin: 0 8px;
    }
  }
}
`;

const UsersContainer: React.FC<UserContainerProps> = ({ }) => {
  const history = useHistory();
  const [users, setUsers] = useState<null | Array<Object>>(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [showChangePwModal, setShowChangePwModal] = useState(false);
  const [showAddEditUserModal, setShowAddEditUserModal] = useState(false);
  const [showEditUserAccessModal, setShowEditUserAccessModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<Object | null>(null);
  const [isCreate, setIsCreate] = useState(true);
  const { loading, error, data } = useQuery(USERS_QUERY);
  const [userDeleteMutation] = useMutation(USER_DELETE_MUTATION);
  const [userUpdateMutation] = useMutation(USER_UPDATE_MUTATION);
  const { data: me } = useQuery(ME_QUERY);

  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('videos', data);
  // }

  const MenuItems = (item) => {

    const onDeleteAction = (_record) => {
      userDeleteMutation({
        variables: {
          _id: _record?._id,
        },
        refetchQueries: [{ query: USERS_QUERY }, { query: ME_QUERY }]
      }).then(data => {
        console.log('mutation data', data)
        message.success('Successfully deleted.')
      })
        .catch(err => {
          const errorMessage = err.toString().replace('Error: GraphQL error:', '');
          message.error(errorMessage);
        });
    }

    const onDisableAction = async (_record) => {
      userUpdateMutation({
        variables: {
          _id: _record?._id,
          input: {
            firstName: _record?.firstName,
            lastName: _record?.lastName,
            role: _record?.role,
            email: _record?.email,
            disabled: !_record?.disabled,
          },
        },
        refetchQueries: [{ query: USERS_QUERY }, { query: ME_QUERY }]
      }).then(data => {
        console.log('mutation data', data);
        message.success('User updated successfully')
      })
        .catch(err => {
          const errorMessage = err.toString().replace('Error: GraphQL error:', '');
          message.error(errorMessage);
        });
    }

    return (
      <StyledMenu>
        <Menu.Item>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Disable</span>
            <Switch checkedChildren="on" unCheckedChildren="off" checked={item?.disabled} onChange={() => onDisableAction(item)} />
          </div>
        </Menu.Item>
        {
          (me.me?.role !== item?.role) && (
            <Menu.Item>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  Modal.confirm({
                    title: `Are you sure to delete user ${item?.firstName} ${item?.lastName}`,
                    onOk: () => onDeleteAction(item),
                    onCancel: () => console.log("cancelled...")
                  })
                }}
              >
                <span>Delete</span>
                <Icon
                  type="delete"
                  style={{
                    fontSize: "18px",
                    margin: "0 10px 0 0",
                  }}
                />
              </div>
            </Menu.Item>
          )
        }
      </StyledMenu>
    )
  };


  const openAddEditUserModal = selectedUser => {
    if (selectedUser) setIsCreate(false);
    setCurrentItem(selectedUser);
    setShowAddEditUserModal(true);
  };
  const openChangePwModal = selectedUser => {
    if (selectedUser) setIsCreate(false);
    setCurrentItem(selectedUser);
    setShowChangePwModal(true);
  };
  const openEditUserAccessModal = selectedUser => {
    setCurrentItem(selectedUser);
    setShowEditUserAccessModal(true);
  };
  const closeAddEditUserModal = () => {
    setIsCreate(true);
    setShowAddEditUserModal(false);
    setCurrentItem(null);
  };
  const closeEditUserAccessModal = () => {
    setIsCreate(true);
    setShowEditUserAccessModal(false);
    setCurrentItem(null);
  };
  const closeChangePwModal = () => {
    setIsCreate(true);
    setShowChangePwModal(false);
    setCurrentItem(null);
  };

  const columns = [
    {
      key: 'firstName',
      title: 'First Name',
      render: record => <span>{record.firstName}</span>,
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'lastName',
      title: 'Last Name',
      render: record => <span>{record.lastName}</span>,
      sorter: (a, b) => a.lastName.length - b.lastName.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'email',
      title: 'Email',
      render: record => {
        return <span>{record.email}</span>;
      },
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'role',
      title: 'Role',
      render: record => {
        return <span>{record.role}</span>;
      },
      sorter: (a, b) => a.role.length - b.role.length,
      sortDirections: ['descend', 'ascend'],
    },

    {
      key: 'createdAt',
      title: 'Created At',
      render: record => moment(record.createdAt).format('Do MMMM, hh:mm a'),
      sorter: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button type="primary" onClick={() => openAddEditUserModal(record)}>
              Edit User
            </Button>
          </span>
          <span style={{ marginRight: 10 }}>
            <Button type="primary" onClick={() => openChangePwModal(record)}>
              Change Password
            </Button>
          </span>
          {record.role !== 'admin' && (
            <span style={{ marginRight: 10 }}>
              <Tooltip placement="bottom" title="Edit user's access in the dashboard" key="Edit user's access in the dashboard">
                <Button type="primary" onClick={() => openEditUserAccessModal(record)}>
                  Edit User Access
                </Button>
              </Tooltip>
            </span>
          )}
          {/* <span>
            <Popconfirm title="Are you sure delete this video?" onConfirm={() => onDeleteVideo(record._id, index)} okText="Yes" cancelText="No">
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span> */}
        </>
      ),
    },
    {
      render: (text, record) => {
        return (
          <> {
            (me.me?.role === 'admin' && me?.me?._id !== record?._id) && (
              <Dropdown overlayStyle={{ width: 150 }} trigger={["hover"]} overlay={MenuItems(record)}>
                <Param className="ant-dropdown-link">
                  <Icon type="ellipsis" />
                </Param>
              </Dropdown>
            )
          }
          </>
        );
      },
    },
  ];

  const onAddNewUser = () => {
    // history.push({
    //   pathname: 'videoUpload',
    //   search: `?goLive=${goLive ? '1' : '0'}`,
    //   state: { goLive: goLive },
    // });
  };
  // const onDeleteVideo = (id, index) => {
  //   message.loading({ content: 'Deleting video.', key: 'deleteMessage', duration: 0 });
  //   deleteVideo({ variables: { _id: id } })
  //     .then((data) => {
  //       if (videos) {
  //         let temp = [...videos];
  //         temp.splice(index, 1);
  //         setVideos(temp);
  //       }
  //       message.destroy();
  //       notification.success({
  //         message: 'Video Deleted',
  //         duration: 0,
  //         description: 'Video was deleted successfully.',
  //       });
  //     })
  //     .catch((err) => {
  //       message.destroy();
  //       notification.error({
  //         message: 'Video Delete Failed',
  //         duration: 0,
  //         description: 'Video was not deleted.',
  //       });
  //     });
  // };

  return (
    <>
      <Row style={{ marginTop: '30px' }}>
        <Col span={2} offset={1}>
          <div>
            <Tooltip placement="bottom" title="Add new user" key="Add new user">
              <Button type="primary" onClick={() => openAddEditUserModal(null)}>
                Add New User
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <AntdTable loading={tableLoading} pagination={false} rowKey={record => record._id} columns={columns} dataSource={data.users} />
        </Col>
      </Row>
      <AddEditUserModal
        isCreate={isCreate}
        modalVisible={showAddEditUserModal}
        currentItem={currentItem}
        handleCancel={closeAddEditUserModal}
        handleOk={closeAddEditUserModal}
      />
      <EditUserAccessModal
        modalVisible={showEditUserAccessModal}
        currentItem={currentItem}
        handleCancel={closeEditUserAccessModal}
        handleOk={closeEditUserAccessModal}
      />
      <ChangePwModal modalVisible={showChangePwModal} selectedUser={currentItem} closeModal={closeChangePwModal} />
    </>
  );
};
export default UsersContainer;
