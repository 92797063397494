import { gql } from 'apollo-boost';
import { FLAGGED_BOOKINGS_FRAGMENT } from '../flaggedBookingsFragment';

export const ENABLE_CLIENT_SELF_SERVICE_MUTATION = gql`
  mutation enableClientSelfService($_id: ID!, $enable: Boolean!) {
    enableClientSelfService(_id: $_id, enable: $enable) {
      ...BookingFlag
    }
  }
  ${FLAGGED_BOOKINGS_FRAGMENT}
`;
