import React from 'react';
import { Modal, Progress, Icon } from 'antd';

const UploadProgressModal = ({ visible, progressPercent }) => {
  return (
    <Modal title="Uploading" visible={visible} footer={null} maskClosable={false} closable={false}>
      {progressPercent == 100 ? (
        <span>
          <Icon type="loading" style={{ fontSize: 24, marginRight: 15 }} spin />
          Please wait. Finalizing...
        </span>
      ) : (
        <Progress percent={progressPercent} />
      )}
    </Modal>
  );
};

export default UploadProgressModal;
