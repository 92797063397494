import { gql } from "apollo-boost";
import { EZVGACTIVITY_FRAGMENT } from "../ezVgActivityFragment";

export const CREATE_EZVGACTIVITY_MUTATION = gql`
  mutation ezVgActivityCreate($input: EzVgInput!) {
    ezVgActivityCreate(input: $input) {
      ...EzVgActivity
    }
  }
  ${EZVGACTIVITY_FRAGMENT}
`;
