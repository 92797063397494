import React, { useState, useCallback, useEffect } from 'react';
import { Card, Row, Col, Upload, Button, Input, Form, Icon, Switch, message, Select, Tooltip, DatePicker, Radio, notification, Progress } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import AddActivityModal from 'component/AddActivityModal/AddActivityModal';
import AddTrainerModal from 'component/AddTrainerModal/AddTrainerModal';
import { CREATE_VIDEO_MUTATION, STOP_LIVE_STREAM_MUTATION } from 'apollo/video/mutations';
import { useLocation } from 'react-router-dom';
import Api from 'axios/Api';
import { VIDEOS_QUERY } from 'apollo/video/queries';
import moment from 'moment';
import StreamComp from 'component/StreamComp/StreamComp';
import UploadProgressModal from 'component/UploadProgressModal/UploadProgressModal';
import { GENERATE_PRE_SIGNED_URL_MUTATION } from 'apollo/s3/mutations';

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

interface VideoUploadProps extends FormComponentProps {}

const equipments = [
  { label: 'Body Weight', key: 'bodyWeight' },
  { label: 'Dumbbells', key: 'dumbbells' },
];

class SelectInput extends React.Component<any> {
  handleSelectChange = selectValue => {
    this.triggerChange({ selectValue });
  };
  triggerChange = changedValue => {
    const { onChange, value } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
  };
  render() {
    const { arr, value, onClickAdd } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Select
          labelInValue
          showSearch
          style={{ width: '85%' }}
          placeholder="Select a trainer"
          optionFilterProp="children"
          onChange={this.handleSelectChange}
          value={value.selectValue}
          filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {arr.length > 0 &&
            arr.map((item: any) => (
              <Option key={item._id} value={item._id}>
                {item.name}
              </Option>
            ))}
        </Select>
        <Tooltip placement="bottom" title="Add New Trainer" key="Add New Trainer">
          <Button style={{ width: '10%' }} type="primary" onClick={onClickAdd}>
            <Icon type="plus" />
          </Button>
        </Tooltip>
      </div>
    );
  }
}

const VideoUploadComponent: React.FC<VideoUploadProps> = ({ form }) => {
  const location: any = useLocation();
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [trainers, setTrainers] = useState<null | Array<Object>>(null);
  const [videoId, setVideoId] = useState<null | String>(null);
  const [streamId, setStreamId] = useState<null | String>(null);
  const [activities, setActivities] = useState<null | Array<Object>>(null);
  const [videoParam, setVideoParam] = useState({
    title: undefined,
    description: undefined,
    trainer: undefined,
    activity: undefined,
    videoFile: '',
    premiere: false,
    allowComments: false,
    premiereAt: null,
    intensity: 'low',
    equipments: [],
    tags: [],
  });
  const [filelists, setFilelists] = useState<any>([]);
  const [goLive, setGoLive] = useState<boolean>(false);
  const [showAddTrainerModal, setShowAddTrainerModal] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [stopLiveStream] = useMutation(STOP_LIVE_STREAM_MUTATION);
  const [createVideo] = useMutation(CREATE_VIDEO_MUTATION, {
    update(cache, { data: { createVideo } }) {
      const cachedData: any = cache.readQuery({ query: VIDEOS_QUERY });
      cache.writeQuery({
        query: VIDEOS_QUERY,
        data: { videos: [...cachedData.videos, createVideo] },
      });
    },
  });
  const [generatePresignedUrl] = useMutation(GENERATE_PRE_SIGNED_URL_MUTATION);

  const { getFieldDecorator } = form;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: 12 },
      lg: { span: 12 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      sm: {
        span: 24,
        offset: 0,
      },
      md: {
        span: 12,
        offset: 8,
      },
      lg: {
        span: 12,
        offset: 6,
      },
    },
  };
  useEffect(() => {
    if (location) {
      let queryParams = new URLSearchParams(location.search);
      let goLiveQuery = queryParams.get('goLive');
      setGoLive(goLiveQuery == '1' ? true : false);
    }
  }, [location]);

  const renderEquipments = equipments.map(item => (
    <Option key={item.key} value={item.key}>
      {item.label}
    </Option>
  ));
  const resetFields = () => {
    form.resetFields();
    setVideoParam({
      title: undefined,
      description: undefined,
      trainer: undefined,
      activity: undefined,
      videoFile: '',
      premiere: false,
      allowComments: false,
      premiereAt: null,
      intensity: 'low',
      equipments: [],
      tags: [],
    });
    setProgressPercent(0);
    setFilelists([]);
    setIsLoading(false);
  };

  const openAddTrainerModal = () => {
    setShowAddTrainerModal(true);
  };
  const openAddActivityModal = () => {
    setShowAddActivityModal(true);
  };

  const handleActivityModalCancel = () => {
    setShowAddActivityModal(false);
  };
  const handleTrainerModalCancel = () => {
    setShowAddTrainerModal(false);
  };
  useQuery(TRAINERS_QUERY, {
    onCompleted: data => {
      // console.log('trainers', data.trainers);
      setTrainers(data.trainers);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      // console.log('activities', data);
      setActivities(data.activities);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });

  const checkValidation = (rule, value, callback) => {
    if (value.selectValue) {
      return callback();
    }
    callback('Cannot be blank!');
  };

  const onAllowCommentsChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, allowComments: val }));
  };
  const onPremiereChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, premiere: val }));
  };

  const onDateChange = val => {
    setVideoParam(prev => ({ ...prev, premiereAt: val }));
  };
  const onIntensityChange = val => {
    // console.log('intensity', val);
    setVideoParam(prev => ({ ...prev, intensity: val }));
  };
  const handleEquipmentChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, equipments: val }));
  };

  const beforeUpload = file => {
    setFilelists(prev => [...prev, file]);
    return false;
  };

  const onRemoveFile = file => {
    console.log('here', file);
    setFilelists(prev => {
      const index = prev.indexOf(file);
      const newFileList = prev.slice();
      newFileList.splice(index, 1);
      return [...newFileList];
    });
  };

  const uploadVideo = input => {
    console.log('input', input);
    createVideo({
      variables: {
        input: { ...input },
      },
    })
      .then(result => {
        // console.log('result', result);
        setIsLoading(false);
        setShowProgressModal(false);
        notification.success({
          key: 'uploadingNotification',
          message: 'Video Uploaded',
          description: 'Video was uploaded successfully.',
          duration: 0,
        });
        resetFields();
      })
      .catch(err => {
        console.log('err', err);
        setProgressPercent(0);
        setIsLoading(false);
        setShowProgressModal(false);
        notification.error({
          key: 'uploadingNotification',
          message: 'Video Upload Failed',
          description: err.toString().replace('Error: GraphQL error:', ''),
          duration: 0,
        });
      });
  };

  const onUploadProgress = progressEvent => {
    // console.log('prog event', progressEvent);
    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    // console.log(percentCompleted);
    setProgressPercent(percentCompleted);
  };

  const uploadToS3 = (url, input) => {
    setShowProgressModal(true);
    Api.uploadVideo(url, filelists[0], onUploadProgress)
      .then(data => {
        // console.log('upload done', data);
        setProgressPercent(100);
        uploadVideo(input);
        // if (data.data.success) {
        //   setProgressPercent(100);
        //   uploadVideo(input);
        // } else {
        //   setIsLoading(false);
        //   setShowProgressModal(false);
        //   setProgressPercent(0);
        //   notification.error({
        //     key: 'uploadingNotification',
        //     message: 'Video Upload Failed',
        //     description: err.toString().replace('Error: GraphQL error:', ''),
        //     duration: 0,
        //   });
        // }
      })
      .catch(err => {
        console.log('upload err', err);
        setIsLoading(false);
        setShowProgressModal(false);
        setProgressPercent(0);
        notification.error({
          key: 'uploadingNotification',
          message: 'Video Upload Failed',
          description: err.toString().replace('Error: GraphQL error:', ''),
          duration: 0,
        });
      });
  };

  const onSubmit = () => {
    if (!goLive) {
      if (filelists.length > 1) {
        message.error('Upload one video at a time');
        return;
      }
      form.validateFields(async (formErrors, values: any) => {
        if (formErrors) {
          return;
        }
        // return;
        let date = values.premiereAt ? moment(values.premiereAt).format('MMM DD, YYYY') : moment().format('MMM DD, YYYY');
        let title = values.title == undefined || values.title == '' ? `${values.activity.selectValue.label} - ${date}` : values.title;
        setIsLoading(true);
        generatePresignedUrl({
          variables: {
            input: { type: 'put', mime: filelists[0].type },
          },
        })
          .then(res => {
            const { url, type, vid } = res.data.generatePreSignedURL;
            const vidType = filelists[0].type.split('/')[1] == 'quicktime' ? 'mov' : filelists[0].type.split('/')[1];
            const input = {
              vid: vid,
              videoType: vidType,
              mimeType: filelists[0].type,
              rawMimeType: filelists[0].type,
              title: title,
              isLive: goLive,
              description: values.description,
              tags: values.tags,
              activity: values.activity.selectValue.key,
              trainer: values.trainer.selectValue.key,
              premium: false,
              intensity: values.intensity,
              equipments: values.equipments,
              premiere: videoParam.premiere,
              allowComments: videoParam.allowComments,
              ...(videoParam.premiere && {
                premiereAt: values.premiereAt.toISOString(),
              }),
            };
            uploadToS3(url, input);
          })
          .catch(err => {
            setIsLoading(false);
            setShowProgressModal(false);
            setProgressPercent(0);
            notification.error({
              key: 'uploadingNotification',
              message: 'Video Upload Failed',
              description: err.toString().replace('Error: GraphQL error:', ''),
              duration: 0,
            });
          });
      });
    } else {
      console.log('live stream');
      // streamApi.createStream();
    }
  };

  const liveStart = _streamId => {
    return new Promise((resolve, reject) => {
      form.validateFields(async (formErrors, values: any) => {
        if (formErrors) {
          reject('err');
          return null as any;
        }
        // return;
        // message.loading({
        //   content: 'uploading',
        //   key: 'uploading',
        //   duration: 0,
        // });
        // setIsLoading(true);
        let date = values.premiereAt ? moment(values.premiereAt).format('MMM DD, YYYY') : moment().format('MMM DD, YYYY');
        let title = values.title == undefined || values.title == '' ? `${values.activity.selectValue.label} - ${date}` : values.title;

        const input = {
          vid: _streamId,
          title: title,
          isLive: goLive,
          description: values.description,
          tags: values.tags,
          activity: values.activity.selectValue.key,
          trainer: values.trainer.selectValue.key,
          premium: false,
          intensity: values.intensity,
          equipments: values.equipments,
          premiere: videoParam.premiere,
          allowComments: videoParam.allowComments,
          ...(videoParam.premiere && {
            premiereAt: values.premiereAt.toISOString(),
          }),
        };
        console.log('input live', input);
        createVideo({
          variables: {
            input: { ...input },
          },
        })
          .then(result => {
            setStreamId(result.data.createVideo.vid);
            setVideoId(result.data.createVideo._id);
            resolve(result);
            // console.log('result', result);
            // setIsLoading(false);
            // message.success({
            //   content: 'Video Uploaded Successfully',
            //   key: 'uploading',
            //   duration: 2,
            // });
            // resetFields();
          })
          .catch(err => {
            console.log('err', err);
            // setIsLoading(false);
            notification.error({
              message: 'Stream start Failed',
              description: err.toString().replace('Error: GraphQL error:', ''),
              duration: 0,
            });
          });
      });
    });
  };

  const liveEnd = () => {
    stopLiveStream({
      variables: {
        _id: videoId,
        vid: streamId,
      },
    }).then(done => {
      notification.success({
        message: 'Stream Ended',
        description: 'Streaming ended successfully.',
        duration: 0,
      });
    });
  };

  return (
    <>
      {goLive && (
        <Row>
          <Col span={16} offset={1}>
            <StreamComp onStart={liveStart} onEnd={liveEnd} setStreaming={setStreaming} />
          </Col>
        </Row>
      )}
      {trainers && activities && !streaming && (
        <Row style={{ marginTop: 10 }}>
          <Col span={16} offset={1}>
            <Card title={goLive ? 'Start Streaming (Fill up the form before start live)' : 'Upload Video to Library'}>
              <Form {...formItemLayout}>
                <Form.Item label="Title">
                  {getFieldDecorator('title', {
                    initialValue: videoParam.title,
                  })(<Input placeholder="Enter Title" />)}
                </Form.Item>
                <Form.Item label="Description">
                  {getFieldDecorator('description', {
                    initialValue: videoParam.description,
                  })(<TextArea rows={4} placeholder="Enter Description" />)}
                </Form.Item>
                <Form.Item label="Tags">
                  {getFieldDecorator('tags', {
                    initialValue: videoParam.tags,
                  })(<Select mode="tags" style={{ width: '100%' }} placeholder="Enter Tags"></Select>)}
                </Form.Item>
                <Form.Item label="Trainer">
                  {getFieldDecorator('trainer', {
                    initialValue: { selectValue: videoParam.trainer },
                    rules: [
                      {
                        required: true,
                        validator: checkValidation,
                      },
                    ],
                  })(<SelectInput arr={trainers} onClickAdd={openAddTrainerModal} />)}
                </Form.Item>
                <Form.Item label="Activity">
                  {getFieldDecorator('activity', {
                    initialValue: { selectValue: videoParam.activity },
                    rules: [
                      {
                        required: true,
                        validator: checkValidation,
                      },
                    ],
                  })(<SelectInput arr={activities} onClickAdd={openAddActivityModal} />)}
                </Form.Item>
                <Form.Item label="Intensity">
                  {getFieldDecorator('intensity', {
                    initialValue: videoParam.intensity,
                    rules: [
                      {
                        required: true,
                        message: 'Please input the actvity!',
                      },
                    ],
                  })(
                    <Radio.Group onChange={onIntensityChange}>
                      <Radio.Button value="low">Low</Radio.Button>
                      <Radio.Button value="medium">Medium</Radio.Button>
                      <Radio.Button value="high">High</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item label="Equiment Needed">
                  {getFieldDecorator('equipments', {
                    initialValue: videoParam.equipments,
                  })(
                    <Select mode="tags" style={{ width: '100%' }} placeholder="Select Equipment" onChange={handleEquipmentChange}>
                      {renderEquipments}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item label="Allow Comments">
                  {getFieldDecorator('allowComments', {
                    initialValue: { checked: videoParam.allowComments },
                    rules: [
                      {
                        required: true,
                        message: 'Please input the title of Video!',
                      },
                    ],
                  })(<Switch checked={videoParam.allowComments} onChange={onAllowCommentsChange} />)}
                </Form.Item>
                {/* {!goLive && ( */}
                <>
                  <Form.Item label="Premiere">
                    {getFieldDecorator('premiere', {
                      initialValue: { checked: videoParam.premiere },
                      rules: [
                        {
                          required: true,
                          message: 'Please input the title of Video!',
                        },
                      ],
                    })(<Switch checked={videoParam.premiere} onChange={onPremiereChange} />)}
                  </Form.Item>
                  {videoParam.premiere && (
                    <Form.Item label="Premiere At">
                      {getFieldDecorator('premiereAt', {
                        initialValue: videoParam.premiereAt,
                        rules: [
                          {
                            required: true,
                            message: 'Please input premiere date!',
                          },
                        ],
                      })(<DatePicker showTime onChange={onDateChange} />)}
                    </Form.Item>
                  )}
                </>
                {/* )} */}
                {!goLive && (
                  <Form.Item wrapperCol={{ span: 24 }} required={true}>
                    {getFieldDecorator('filelists', {
                      initialValue: { fileList: filelists },
                      rules: [
                        {
                          required: true,
                          message: 'Please upload a video!',
                        },
                      ],
                    })(
                      <Dragger accept=".mov,video/*" beforeUpload={beforeUpload} onRemove={onRemoveFile} fileList={filelists}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">Click or drag video to this area to upload</p>
                      </Dragger>
                    )}
                  </Form.Item>
                )}
                {!goLive && (
                  <>
                    {!isLoading && (
                      <Form.Item {...tailFormItemLayout}>
                        <div style={{ textAlign: 'right' }}>
                          <Button type="danger" style={{ marginRight: 10 }} onClick={resetFields}>
                            Cancel
                          </Button>
                          {goLive ? (
                            <Button type="primary" htmlType="submit" onClick={onSubmit}>
                              Start Live
                            </Button>
                          ) : (
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={onSubmit}
                              disabled={filelists.length > 0 ? false : true}
                              loading={isLoading}
                            >
                              {isLoading ? 'Uploading' : 'Upload'}
                            </Button>
                          )}
                        </div>
                      </Form.Item>
                    )}
                  </>
                )}
              </Form>
            </Card>
          </Col>
        </Row>
      )}
      <UploadProgressModal visible={showProgressModal} progressPercent={progressPercent} />
      <AddActivityModal
        isCreate={true}
        currentItem={false}
        modalVisible={showAddActivityModal}
        handleOk={handleActivityModalCancel}
        handleCancel={handleActivityModalCancel}
      />
      <AddTrainerModal
        isCreate={true}
        currentItem={false}
        modalVisible={showAddTrainerModal}
        handleOk={handleTrainerModalCancel}
        handleCancel={handleTrainerModalCancel}
      />
    </>
  );
};
const VideoUploadContainer = Form.create<VideoUploadProps>()(VideoUploadComponent);
export default VideoUploadContainer;
