import { gql } from 'apollo-boost';
import { ACTIVITY_FRAGMENT } from '../activityFragment';

export const CREATE_ACTIVITY_MUTATION = gql`
  mutation createActivity($input: ActivityInput!) {
    createActivity(input: $input) {
      ...Activity
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
