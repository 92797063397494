import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { VIDEO_QUERY } from 'apollo/video/queries/video';
import VideoPlayer from 'component/VideoPlayer/VideoPlayer';
const VideoPlayerModal = ({ visible, closeModal, video }) => {
  // useQuery(VIDEO_QUERY,{variables:{_id:createBrotliDecompress._id}})
  const [videoJsOptions, setVideoJsOptions] = useState<any>(null);

  useEffect(() => {
    if (video) {
      setVideoJsOptions({
        autoplay: true,
        controls: true,
        aspectRatio: '16:9',
        sources: [
          {
            src: video.url,
            // type: 'application/x-mpegURL',
          },
        ],
      });
    }
  }, [video]);

  return (
    <>
      {video && (
        <Modal visible={visible} footer={null} onCancel={closeModal} title={video.title} width={1000}>
          <VideoPlayer videoJsOptions={videoJsOptions} />
        </Modal>
      )}
    </>
  );
};

export default VideoPlayerModal;
