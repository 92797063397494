import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Button, Modal, Form, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { CHANGE_PW_ADMIN_MUTATION } from 'apollo/user/mutations';

interface ChangePwModalProps extends FormComponentProps {
  selectedUser?: any;
  closeModal: any;
  modalVisible: boolean;
}

const ChangePwModalComponent: React.FC<ChangePwModalProps> = ({ form, selectedUser, closeModal, modalVisible }) => {
  const [changePwAdminMutation] = useMutation(CHANGE_PW_ADMIN_MUTATION);
  const [isLoading, setIsLoading] = useState(false);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
  };

  const handleCancelModal = () => {
    resetForm();
    closeModal();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      console.log('input', {
        userId: selectedUser._id,
        secret: values.secret,
        password: values.password,
      });
      setIsLoading(true);
      changePwAdminMutation({
        variables: {
          userId: selectedUser._id,
          secret: values.secret,
          password: values.password,
        },
      })
        .then(() => {
          setIsLoading(false);
          notification.success({
            message: 'Password Changed',
            duration: 0,
            description: 'Password Changed successfully.',
          });
          resetForm();
          closeModal();
        })
        .catch(err => {
          setIsLoading(false);
          const errorMessage = err.toString().replace('Error: GraphQL error:', '');
          notification.error({
            message: 'Change Password Failed',
            duration: 0,
            description: errorMessage,
          });
        });
    });
  };
  return (
    <Modal
      title="Change Password"
      footer={[
        <Button type="primary" onClick={handleCancelModal}>
          Cancel
        </Button>,
        <Button type="primary" onClick={handleSubmit} loading={isLoading}>
          Submit
        </Button>,
      ]}
      visible={modalVisible}
      onOk={handleCancelModal}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="New Password">
          {getFieldDecorator('password', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Please input new password!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Secret">
          {getFieldDecorator('secret', {
            initialValue: '',
            rules: [
              {
                required: true,
                message: 'Please input secret!',
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const ChangePwModal = Form.create<ChangePwModalProps>()(ChangePwModalComponent);
export default ChangePwModal;
