import { gql } from 'apollo-boost';
import { TRAINER_FRAGMENT } from '../trainerFragment';

export const UPDATE_TRAINER_MUTATION = gql`
  mutation updateTrainer($_id: ID!, $input: TrainerInput!) {
    updateTrainer(_id: $_id, input: $input) {
      ...Trainer
    }
  }
  ${TRAINER_FRAGMENT}
`;
