import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from './../videoFragmet';

export const CREATE_VIDEO_MUTATION = gql`
  mutation createVideo($input: VideoCreateInput!) {
    createVideo(input: $input) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
