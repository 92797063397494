import { gql } from 'apollo-boost';
import { TRAINER_FRAGMENT } from '../trainerFragment';

export const TRAINERS_QUERY = gql`
  query trainers {
    trainers {
      ...Trainer
    }
  }
  ${TRAINER_FRAGMENT}
`;
