import { gql } from 'apollo-boost';
import { TRAINER_FRAGMENT } from '../trainerFragment';

export const CREATE_TRAINER_MUTATION = gql`
  mutation createTrainer($input: TrainerInput!) {
    createTrainer(input: $input) {
      ...Trainer
    }
  }
  ${TRAINER_FRAGMENT}
`;
