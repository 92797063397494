import { gql } from "apollo-boost";
import { EZVGACTIVITY_FRAGMENT } from "../ezVgActivityFragment";

export const EZVGACTIVITIES_QUERY = gql`
  query ezVgActivities {
    ezVgActivities {
      ...EzVgActivity
    }
  }
  ${EZVGACTIVITY_FRAGMENT}
`;

export const VGDEFAULTACTIVITIES_QUERY = gql`
  query vgDefaultActivities {
    vgDefaultActivities
  }
`;
