import { gql } from 'apollo-boost';
import { USER_FRAGMENT } from '../userfragment';

export const USER_UPDATE_MUTATION = gql`
  mutation userUpdate($_id: ID!, $input: UserInput!) {
    userUpdate(_id: $_id, input: $input) {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;
