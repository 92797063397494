import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import PrivateRoutes from './PrivateRoutes';
import Login from '../view/Auth';
import Error404 from './Error404';
import { getToken } from '../helper';
import ChangePassword from 'view/Main/ChangePassword';

interface Props {}

const RouterPath: React.FC<Props> = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">{getToken() ? <Redirect to="/" /> : <Login />}</Route>
        <Route path="/changepassword" component={ChangePassword} />
        <PrivateRoutes path="/" component={ProtectedRoutes} />
        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterPath;
