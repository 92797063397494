import { gql } from 'apollo-boost';
import { TRAINER_FRAGMENT } from '../trainerFragment';

export const TRAINER_QUERY = gql`
  query trainer($_id: ID!) {
    trainer(_id: $_id) {
      ...Trainer
    }
  }
  ${TRAINER_FRAGMENT}
`;
