import React, { useState } from 'react';
import { LayoutProps } from './interface';
import { Layout as AntdLayout } from 'antd'
import Sidebar from './Sidebar';
import Header from './Header';
import { StyledLayout, StyledContent } from './LayoutStyles';
import Scrollbar from 'react-smooth-scrollbar';


const Layout: React.FC<LayoutProps> = ({
    children
}) => {
    const [collapse, setCollapse] = useState(false);

    return (
        <StyledLayout>
            <Sidebar
                collapse={collapse}
                // menu={menu}
            />
            <AntdLayout>
                <Header
                    toggleSidebar={setCollapse}
                    collapse={collapse}
                />
                <Scrollbar className="content-scrollbar" style={{height: '100%'}}>
                    <StyledContent>
                        {children}
                    </StyledContent>
                </Scrollbar>
            </AntdLayout>
        </StyledLayout>
    )
}

export default Layout
