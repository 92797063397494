export const TrainerHubDefaultButtons = [
  {
    link: 'https://vg.tpasc.ca/checkin',
    label: 'Check-in',
    color: '#0D2C6C',
  },
  {
    link: 'https://vg.tpasc.ca/parq',
    label: 'Par Q Form',
    color: 'green',
  },
  {
    link: 'https://vg.tpasc.ca/gopro',
    label: 'Upgrade to Pro',
    color: '#0D2C6C',
  },
  {
    link: 'https://vg.tpasc.ca/personalcoaching',
    label: 'Sign up for Remote Coaching',
    color: '#0D2C6C',
  },
  {
    link: 'https://vg.tpasc.ca/personaltraining',
    label: 'Sign up for Personal Training',
    color: '#0D2C6C',
  },
  {
    link: 'https://vg.tpasc.ca/purchasemoretraining',
    label: 'Purchase More Personal Training',
    color: '#0D2C6C',
  },
];
