import gql from "graphql-tag";
import { GATES_FRAGMENT } from "../gatesFragment";

export const GATES_QUERY = gql`
  query gates {
    gates {
      ...Gate
    }
  }
  ${GATES_FRAGMENT}
`;
