import { gql } from "apollo-boost";
import { FLAGGED_BOOKINGS_FRAGMENT } from "../flaggedBookingsFragment";

export const BOOKING_FLAG_UPDATE_MUTATION = gql`
  mutation updateBookingFlag($id: ID!, $input: BookingFlagUpdateInput!) {
    updateBookingFlag(_id: $id, input: $input) {
      ...BookingFlag
    }
  }
  ${FLAGGED_BOOKINGS_FRAGMENT}
`;
