import { gql } from "apollo-boost";
import { EZVGACTIVITY_FRAGMENT } from "../ezVgActivityFragment";

export const UPDATE_EZVGACTIVITY_MUTATION = gql`
  mutation ezVgActivityUpdate($_id: ID!, $input: EzVgInput!) {
    ezVgActivityUpdate(_id: $_id, input: $input) {
      ...EzVgActivity
    }
  }
  ${EZVGACTIVITY_FRAGMENT}
`;
