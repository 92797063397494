import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Row, Col, Button, Icon, Modal, Form, Input, InputNumber, message, AutoComplete, Switch, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { USER_CREATE_MUTATION, USER_UPDATE_MUTATION } from 'apollo/user/mutations';
import { USERS_QUERY } from 'apollo/user/queries';

interface UserModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const AddEditUserModalComponent: React.FC<UserModalProps> = ({ form, isCreate, currentItem, handleCancel, handleOk, modalVisible }) => {
  const [createUser] = useMutation(USER_CREATE_MUTATION, {
    update: (cache, { data: { userCreate } }) => {
      const cachedData: any = cache.readQuery({ query: USERS_QUERY });
      cache.writeQuery({
        query: USERS_QUERY,
        data: { users: [...cachedData.users, userCreate] },
      });
    },
    // refetchQueries: ['users'],
  });
  const [updateUser] = useMutation(USER_UPDATE_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [userParam, setUserParam] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
  });

  useEffect(() => {
    if (currentItem) {
      setUserParam(prev => ({
        ...prev,
        firstName: currentItem.firstName,
        lastName: currentItem.lastName,
        email: currentItem.email,
        password: currentItem.password,
        role: currentItem.role,
      }));
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setUserParam({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      role: '',
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        ...userParam,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        ...(isCreate ? { password: values.password } : {}),
        role: currentItem ? currentItem.role : 'staff',
        // role: 'staff',
      };
      if (isCreate) {
        createUser({
          variables: {
            input: {
              ...params,
            },
          },
        })
          .then(mutationResult => {
            setIsLoading(false);
            notification.success({
              message: 'User Created',
              duration: 0,
              description: 'User was created successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'User Create Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      } else {
        updateUser({
          variables: {
            _id: currentItem._id,
            input: {
              ...params,
            },
          },
        })
          .then(updatedResult => {
            setIsLoading(false);
            notification.success({
              message: 'User Updated',
              duration: 0,
              description: 'User was updated successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch(e => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'User Update Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      }
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New User' : 'Update Existing User'}
      footer={[
        <Button type="primary" onClick={handleSubmit} key="addUpdate" loading={isLoading}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="First Name">
          {getFieldDecorator('firstName', {
            initialValue: userParam.firstName,
            rules: [
              {
                required: true,
                message: 'Please input the first name of user!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Last Name">
          {getFieldDecorator('lastName', {
            initialValue: userParam.lastName,
            rules: [
              {
                required: true,
                message: 'Please input the last name of user!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            initialValue: userParam.email,
            rules: [
              {
                required: true,
                message: 'Please input the email of user!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        {isCreate && (
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              initialValue: userParam.password,
              rules: [
                {
                  required: true,
                  message: 'Please input the password of user!',
                },
              ],
            })(<Input />)}
          </Form.Item>
        )}
        {/* <Row>
          <Col span={11}>
            <Form.Item label="Role">
              {getFieldDecorator('role', {
                initialValue: userParam.role,
                rules: [
                  {
                    required: true,
                    message: 'Please input the role of user!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item label="Gender">
              {getFieldDecorator('gender', {
                initialValue: userParam.gender,
                rules: [
                  {
                    required: true,
                    message: 'Please input the gender of user!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </Modal>
  );
};
const AddEditUserModal = Form.create<UserModalProps>()(AddEditUserModalComponent);
export default AddEditUserModal;
