import React, { useState } from 'react';
import { Row, Col, Steps, Form, Input, notification, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import AntdButton from 'component/elements/AntdButton';
import { useMutation } from '@apollo/react-hooks';
import { EZ_CLIENT_DETAIL_MUTATION, VG_LINK_EZ_MUTATION } from 'apollo/vgWebappSettings/mutations';

const { Step } = Steps;
const { Option } = Select;

interface VgLinkEzProps extends FormComponentProps {}

const VgLinkEzComp = ({ form }) => {
  const [current, setCurrent] = useState(0);
  const [client, setClient] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [clientParam, setClientParam] = useState({
    clientId: '',
    email: '',
  });
  const [linkParam, setLinkParam] = useState<any>({
    clientId: '',
    email: '',
  });
  const [ezClientDetailMutation] = useMutation(EZ_CLIENT_DETAIL_MUTATION);
  const [vgLinkEzMutation] = useMutation(VG_LINK_EZ_MUTATION);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setClientParam({
      clientId: '',
      email: '',
    });
    setLinkParam({
      clientId: '',
      email: '',
    });
  };

  const onSubmitFirst = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setLoading(true);
      ezClientDetailMutation({
        variables: {
          clientId: values.clientId,
          email: values.email,
        },
      })
        .then(mutationResult => {
          setLoading(false);
          console.log('mut', mutationResult);
          resetForm();
          setClient(mutationResult.data.ezClientDetail);
          setEmails(mutationResult.data.ezClientDetail.email);
          setLinkParam({
            clientId: mutationResult.data.ezClientDetail.clientId.toString(),
            email: values.email,
          });
          setCurrent(1);
        })
        .catch(e => {
          setLoading(false);
          const errorMessage = e.toString().replace('Error: GraphQL error:', '');
          notification.error({
            message: 'EZ CLIENT FAILED',
            duration: 0,
            description: errorMessage,
          });
        });
    });
  };

  const onSubmitSecond = () => {
    setLoading(true);
    vgLinkEzMutation({
      variables: {
        clientId: linkParam.clientId,
        email: linkParam.email,
      },
    })
      .then(mutationResult => {
        setLoading(false);
        console.log('mut', mutationResult);
        setCurrent(0);
        setEmails([]);
        setClient(null);
        notification.success({
          message: 'Link Succes',
          duration: 0,
        });
        setCurrent(0);
      })
      .catch(e => {
        setLoading(false);
        const errorMessage = e.toString().replace('Error: GraphQL error:', '');
        notification.error({
          message: 'Link Failed',
          duration: 0,
          description: errorMessage,
        });
      });
  };

  return (
    <Row>
      <Col offset={1} span={20} style={{ padding: 20, background: 'white' }}>
        <div>
          <Steps current={current}>
            <Step key={0} title="Get EZ Client" />
            <Step key={1} title="Link with VG" />
          </Steps>
        </div>
        {!client && current == 0 && (
          <div style={{ marginTop: 10 }}>
            <Form layout="horizontal">
              <Form.Item label="EZ Facility Client ID">
                {getFieldDecorator('clientId', {
                  initialValue: clientParam.clientId,
                  rules: [
                    {
                      required: true,
                      message: 'Please input the client id!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Virtuagym Email">
                {getFieldDecorator('email', {
                  initialValue: clientParam.email,
                  rules: [
                    {
                      required: true,
                      message: 'Please input the email!',
                    },
                  ],
                })(<Input />)}
                <span>Make sure this email already has a registered VG account</span>
              </Form.Item>
              <Form.Item>
                <div style={{ textAlign: 'right' }}>
                  <AntdButton onClick={onSubmitFirst} loading={loading} type="primary">
                    Submit
                  </AntdButton>
                </div>
              </Form.Item>
            </Form>
          </div>
        )}
        {client && current == 1 && (
          <div style={{ marginTop: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <span>Client ID:</span>
              </div>
              <div style={{ marginLeft: 10 }}>
                <span>{client.clientId}</span>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <span>First Name:</span>
              </div>
              <div style={{ marginLeft: 10 }}>
                <span>{client.firstName}</span>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <span>Last Name:</span>
              </div>
              <div style={{ marginLeft: 10 }}>
                <span>{client.lastName}</span>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <span>Membership Number:</span>
              </div>
              <div style={{ marginLeft: 10 }}>
                <span>{client.membershipNumber}</span>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <span>Email to Link:</span>
              </div>
              <div style={{ marginLeft: 10 }}>
                <Select style={{ width: '400px' }} value={linkParam.email} onChange={val => setLinkParam(prev => ({ ...prev, email: val }))}>
                  {emails &&
                    emails.map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
            <div style={{ textAlign: 'right' }}>
              <AntdButton
                style={{ marginRight: 10 }}
                onClick={() => {
                  setCurrent(0);
                  setEmails([]);
                  setClient(null);
                  resetForm();
                }}
              >
                Back
              </AntdButton>
              <AntdButton onClick={onSubmitSecond} loading={loading} type="primary">
                Link
              </AntdButton>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

const VgLinkEzContainer = Form.create<VgLinkEzProps>()(VgLinkEzComp);
export default VgLinkEzContainer;
