import React from 'react'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

interface Props {
    color?: String;
}

const LoadingSpinner: React.FC<Props> = ({
    color
}) => {
    return (
        <React.Fragment>
                <LOADER>
                    <Loader type='TailSpin' color={(color === undefined)? '#202541' : color}/>
                </LOADER>
        </React.Fragment>
    )
}

export default LoadingSpinner

const LOADER = styled.div`
    position: absolute;
    height: 80vh;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
