import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button, Popconfirm, message, notification } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { DELETE_ACTIVITY_MUTATION } from 'apollo/activity/mutations';
import LoadingSpinner from 'component/Loader';

const ActivityTable = ({ onEdit }) => {
  const [tableLoading, setTableLoading] = useState(false);
  const [deleteActivity] = useMutation(DELETE_ACTIVITY_MUTATION, { refetchQueries: ['activities'] });
  const onDeleteActivity = (id) => {
    message.loading({ content: 'Deleting activity.', key: 'deleteMessage', duration: 0 });
    deleteActivity({ variables: { _id: id } })
      .then((data) => {
        // message.success({ content: 'Activity Deleted Successfully', key: 'deleteMessage', duration: 2 });
        message.destroy();
        notification.success({
          message: 'Activity Deleted',
          duration: 0,
          description: 'Activity was deleted successfully.',
        });
      })
      .catch((err) => {
        message.destroy();
        notification.error({
          message: 'Activity Delete Failed',
          duration: 0,
          description: 'Activity was not deleted.',
        });
        // message.error({ content: 'Activity Delete Failed', key: 'deleteMessage', duration: 2 });
      });
  };

  const { loading, error, data } = useQuery(ACTIVITIES_QUERY);
  if (loading) return <LoadingSpinner />;

  const columns = [
    {
      key: 'name',
      title: 'Activity',
      render: (record) => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.name}
        </Button>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <>
          <span>
            <Button type="primary" onClick={() => onEdit(record)}>
              Edit
            </Button>
          </span>
          <span style={{ marginLeft: '1em' }}>
            <Popconfirm title="Are you sure delete this activity?" onConfirm={() => onDeleteActivity(record._id)} okText="Yes" cancelText="No">
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span>
        </>
      ),
    },
  ];

  return <AntdTable loading={tableLoading} pagination={false} rowKey={(record) => record._id} columns={columns} dataSource={data.activities} />;
};

export default ActivityTable;
