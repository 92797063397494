import { gql } from "apollo-boost";
import { SETTINGS_FRAGMENT } from "../settingsFragment";

export const SET_SETTINGS_MUTATION = gql`
  mutation setSettings($input: SettingsInput!) {
    setSettings(input: $input) {
      ...Settings
    }
  }
  ${SETTINGS_FRAGMENT}
`;
