import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button, Modal, Form, Input, InputNumber, notification } from "antd";
import { FormComponentProps } from "antd/lib/form/Form";

import {
  CREATE_EZVGACTIVITY_MUTATION,
  UPDATE_EZVGACTIVITY_MUTATION,
} from "apollo/activity/mutations";
import { EZVGACTIVITIES_QUERY } from "apollo/activity/queries";

// const { Option } = Select;

interface EzVgActivityModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const AddEditEzVgActivityModalComponent: React.FC<EzVgActivityModalProps> = ({
  form,
  isCreate,
  currentItem,
  handleCancel,
  handleOk,
  modalVisible,
}) => {
  const [createEzVgActivity] = useMutation(CREATE_EZVGACTIVITY_MUTATION, {
    update: (cache, { data: { ezVgActivityCreate } }) => {
      const cachedData: any = cache.readQuery({ query: EZVGACTIVITIES_QUERY });
      cache.writeQuery({
        query: EZVGACTIVITIES_QUERY,
        data: {
          ezVgActivities: [ezVgActivityCreate, ...cachedData.ezVgActivities],
        },
      });
    },
  });
  const [updateEzVgActivity] = useMutation(UPDATE_EZVGACTIVITY_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [ezVgActivityParams, setEzVgActivityParams] = useState({
    ezAudience: "",
    ezAudienceId: null,
    vgActivity: "",
    vgId: null,
    vgActivityLabel: "",
  });

  useEffect(() => {
    if (currentItem) {
      setEzVgActivityParams((prev) => ({
        ...prev,
        ezAudience: currentItem.ezAudience,
        ezAudienceId: currentItem.ezAudienceId,
        vgActivity: currentItem.vgActivity,
        vgActivityLabel: currentItem.vgActivity,
        vgId: currentItem.vgId,
      }));
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setEzVgActivityParams({
      ezAudience: "",
      ezAudienceId: null,
      vgActivity: "",
      vgId: null,
      vgActivityLabel: "",
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        ezAudience: values.ezAudience,
        ezAudienceId: values.ezAudienceId,
        // vgActivity: ezVgActivityParams.vgActivity,
        vgActivity: values.vgActivityLabel,
      };
      if (values.vgId) {
        params["vgId"] = values.vgId;
      }
      if (isCreate) {
        createEzVgActivity({
          variables: {
            input: {
              ...params,
            },
          },
        })
          .then((mutationResult) => {
            setIsLoading(false);
            notification.success({
              message: "Activity Synced",
              duration: 0,
              description: "Activty Synced successfully.",
            });
            resetForm();
            handleOk();
          })
          .catch((e) => {
            setIsLoading(false);
            const errorMessage = e
              .toString()
              .replace("Error: GraphQL error:", "");
            notification.error({
              message: "User Create Failed",
              duration: 0,
              description: errorMessage,
            });
          });
      } else {
        updateEzVgActivity({
          variables: {
            _id: currentItem._id,
            input: {
              ...params,
            },
          },
        })
          .then((updatedResult) => {
            setIsLoading(false);
            notification.success({
              message: "Activity Updated",
              duration: 0,
              description: "Activity Sync updated successfully.",
            });
            resetForm();
            handleOk();
          })
          .catch((e) => {
            setIsLoading(false);
            const errorMessage = e
              .toString()
              .replace("Error: GraphQL error:", "");
            notification.error({
              message: "User Update Failed",
              duration: 0,
              description: errorMessage,
            });
          });
      }
    });
  };

  // function onVgActivityChange(value) {
  //   form.setFieldsValue({
  //     vgId: vgActivities[value]["id"],
  //   });
  //   setEzVgActivityParams({
  //     ...ezVgActivityParams,
  //     vgActivity: vgActivities[value]["name"],
  //   });
  // }

  return (
    <Modal
      title={isCreate ? "Add New Activty" : "Update Existing Activity"}
      footer={[
        <Button
          type="primary"
          onClick={handleSubmit}
          key="addUpdate"
          loading={isLoading}
        >
          {isCreate ? "Add" : "Update"}
        </Button>,
      ]}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Ez Audience">
          {getFieldDecorator("ezAudience", {
            initialValue: ezVgActivityParams.ezAudience,
            rules: [
              {
                required: true,
                message: "Please input the audience title!",
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Ez Audience Id">
          {getFieldDecorator("ezAudienceId", {
            initialValue: ezVgActivityParams.ezAudienceId,
            rules: [
              {
                required: true,
                message: "Please input the audience id!",
              },
            ],
          })(<InputNumber style={{ width: "100%" }} />)}
        </Form.Item>
        <Form.Item label="VG Activity">
          {getFieldDecorator("vgActivityLabel", {
            initialValue: ezVgActivityParams.vgActivityLabel,
          })(
            <Input />
            // <Select
            //   showSearch
            //   placeholder="Select activity"
            //   optionFilterProp="children"
            //   onChange={onVgActivityChange}
            //   filterOption={(input, option: any) =>
            //     option.props.children
            //       .toLowerCase()
            //       .indexOf(input.toLowerCase()) >= 0
            //   }
            // >
            //   {vgActivities.map((singleActivity, index) => (
            //     <Option value={index} key={index}>
            //       {singleActivity.name}
            //     </Option>
            //   ))}
            // </Select>
          )}
        </Form.Item>
        <Form.Item label="Workout Id">
          {getFieldDecorator("vgId", {
            initialValue: ezVgActivityParams.vgId,
            rules: [
              {
                message: "Please input the vg activity id!",
                required: true,
              },
            ],
          })(<InputNumber style={{ width: "100%" }} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const AddEditEzVgActivityModal = Form.create<EzVgActivityModalProps>()(
  AddEditEzVgActivityModalComponent
);
export default AddEditEzVgActivityModal;
