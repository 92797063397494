import { useMutation } from '@apollo/react-hooks';
import { Form, Input, Button, Select, Row, Col, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { LINK_BARCODE_MUTATION } from 'apollo/vgWebappSettings/mutations';
import React, { useState, useEffect } from 'react';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 4,
    span: 16,
  },
};

interface BarcodeProps extends FormComponentProps {}

const BarcodeComp = ({ form }) => {
  const [loading, setLoading] = useState(false);
  const [cardType, setCardType] = useState<any>('');

  const [linkBarcodeMutation] = useMutation(LINK_BARCODE_MUTATION);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    setCardType('');
    form.resetFields();
  };

  const onLink = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      let input = {};
      if (cardType == 'Member') {
        input = {
          id1: values.id1,
        };
      } else {
        input = {
          id1: values.id1,
          id2: values.id2,
        };
      }
      console.log({
        memberId: +values.memberId,
        type: values.cardType,
        input: input,
      });
      setLoading(true);
      linkBarcodeMutation({
        variables: {
          memberId: +values.memberId,
          type: values.cardType,
          input: input,
        },
      })
        .then(mutationResult => {
          setLoading(false);
          console.log('mut', mutationResult);
          resetForm();
          notification.success({
            message: 'Barcode Linked',
            duration: 0,
          });
        })
        .catch(e => {
          setLoading(false);
          const errorMessage = e.toString().replace('Error: GraphQL error:', '');
          notification.error({
            message: 'Barcode Link Failed',
            duration: 0,
            description: errorMessage,
          });
        });
    });
  };

  return (
    <Row style={{ marginTop: 24 }}>
      <Col
        offset={1}
        span={20}
        style={{
          background: 'white',
          padding: 20,
        }}
      >
        <Form>
          <Form.Item label="Vg member id (Look in Virtuagym Client section)">
            {getFieldDecorator('memberId', {
              initialValue: '',
              rules: [
                {
                  required: true,
                  message: 'Please enter your member ID!',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Card Type">
            {getFieldDecorator('cardType', {
              initialValue: cardType,
              rules: [
                {
                  required: true,
                  message: 'Please enter your member ID!',
                },
              ],
            })(
              <Select
                placeholder="Select Card Type"
                onChange={val => {
                  form.setFieldsValue({
                    id1: '',
                    id2: '',
                  });
                  setCardType(val);
                }}
                style={{ width: '100%', marginBottom: 15 }}
              >
                <Option value="Member">TPASC Member Card</Option>
                <Option value="Student">U of T Tcard</Option>
                <Option value="City">City of Toronto Keytag</Option>
                <Option value="Track Walker">TPASC Track Keytag</Option>
                <Option value="League/Events">League/Events</Option>
              </Select>
            )}
          </Form.Item>
          {cardType === 'Member' && (
            <Form.Item label="Client Id">
              {getFieldDecorator('id1', {
                initialValue: '',
                rules: [
                  {
                    required: true,
                    message: 'Please enter Client Id!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          )}
          {cardType === 'Student' && (
            <>
              <Form.Item label="Library Number">
                {getFieldDecorator('id1', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Library Number!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Student/Employee number">
                {getFieldDecorator('id2', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Student/Employee Number!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </>
          )}
          {cardType === 'City' && (
            <>
              <Form.Item label="Last Name">
                {getFieldDecorator('id1', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Last Name!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Keytag Number">
                {getFieldDecorator('id2', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Key tag number!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </>
          )}
          {cardType === 'Track Walker' && (
            <>
              <Form.Item label="Last Name">
                {getFieldDecorator('id1', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Last Name!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Keytag Number">
                {getFieldDecorator('id2', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Key tag number!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </>
          )}
          {cardType === 'League/Events' && (
            <>
              <Form.Item label="Group ID">
                {getFieldDecorator('id1', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Group ID!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <Form.Item label="Passphrase">
                {getFieldDecorator('id2', {
                  initialValue: '',
                  rules: [
                    {
                      required: true,
                      message: 'Please enter your Key Passphrase!',
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </>
          )}
          <Form.Item {...tailLayout}>
            <Button loading={loading} type="primary" onClick={onLink}>
              Link
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
const BarcodeContainer = Form.create<BarcodeProps>()(BarcodeComp);
export default BarcodeContainer;
