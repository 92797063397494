import React from 'react';
import { Row, Col, Tag } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { AGENDAS_QUERY } from 'apollo/settings/queries';
import AntdTable from '../../../component/elements/AntdTable';
import moment from 'moment';
import LoadingSpinner from 'component/Loader';
import AntdButton from 'component/elements/AntdButton';
import { RESET_AGENDA_HISTORY_MUTATION } from 'apollo/settings/mutations/resetAgendaHistory';

const AgendaContainer = () => {
  const { data, loading } = useQuery(AGENDAS_QUERY);
  const [resetAgendaHistoryMutation, { loading: resetLoading }] = useMutation(RESET_AGENDA_HISTORY_MUTATION, {
    refetchQueries: ['agendas'],
    awaitRefetchQueries: true,
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Run At',
      dataIndex: 'nextRunAt',
      key: 'nextRunAt',
      render: runAt => (runAt ? moment(runAt).format('DD MMM YYYY, h:mm a') : '-'),
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => +new Date(a.runAt) - +new Date(b.runAt),
    },
    {
      title: 'Repeat Interval',
      dataIndex: 'repeatInterval',
      key: 'repeatInterval',
      render: repeatInterval => repeatInterval || '-',
    },
    {
      title: 'Last Run At',
      dataIndex: 'lastRunAt',
      key: 'lastRunAt',
      render: lastRunAt => (lastRunAt ? moment(lastRunAt).format('DD MMM YYYY, h:mm a') : '-'),
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => +new Date(a.lastRunAt) - +new Date(b.lastRunAt),
    },
    {
      title: 'Last Finished At',
      dataIndex: 'lastFinishedAt',
      key: 'lastFinishedAt',
      render: lastFinishedAt => (lastFinishedAt ? moment(lastFinishedAt).format('DD MMM YYYY, h:mm a') : '-'),
      defaultSortOrder: 'descend',
      sorter: (a, b) => +new Date(a.lastFinishedAt) - +new Date(b.lastFinishedAt),
    },
    {
      key: 'status',
      title: 'Status',
      render: record =>
        record.lastFinishedAt && !record.nextRunAt ? (
          <Tag color="#4dad10">Finished</Tag>
        ) : record.lockedAt ? (
          <Tag color="#db2a1d">Error</Tag>
        ) : record.disabled ? (
          <Tag color="#e6d419">Paused</Tag>
        ) : (
          <Tag color="#1d89db">Active</Tag>
        ),
    },
  ];

  const onResetHistory = () => {
    resetAgendaHistoryMutation()
      .then(data => {})
      .catch(err => console.error('err', err));
  };

  if (loading) return <LoadingSpinner />;

  return (
    <Row style={{ marginTop: 24 }}>
      <Col offset={1} span={20} style={{ padding: 20, background: 'white' }}>
        <div style={{ textAlign: 'right' }}>
          <AntdButton loading={resetLoading} type="danger" style={{ marginBottom: 20 }} onClick={onResetHistory}>
            Reset Agenda History
          </AntdButton>
        </div>
        <AntdTable rowKey={record => record._id} dataSource={data.agendas || []} columns={columns} />
      </Col>
    </Row>
  );
};

export default AgendaContainer;
