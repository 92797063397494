import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/react-hooks';

import { StyledSidebar, StyledSidebarHeader } from './sidebarStyles';
import { Menu, Icon, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { ME_QUERY } from 'apollo/user/queries';
import { removeToken } from '../../../helper';

const { SubMenu } = Menu;
interface Props {
  collapse: boolean;
  // menu: Array<string | any>;
}

const Sidebar: React.FC<Props> = ({
  collapse,
  // menu
}) => {
  const [menu, setMenu] = useState(false as any);
  const { loading, error, data } = useQuery(ME_QUERY);

  useEffect(() => {
    if (data) {
      // console.log('data', data);
      if (data.me.role == 'admin') {
        setMenu([
          {
            path: '/',
            component: 'GatesListContainer',
            title: 'Gates',
            icon: 'snippets',
          },

          {
            path: '/flaggedBookings',
            component: 'FlaggedBookingsContainer',
            title: 'Flagged Bookings',
            icon: 'book',
          },
          {
            path: '/users',
            icon: 'usergroup-add',
            component: 'UsersContainer',
            title: 'Users And Roles',
          },
          {
            submenu: true,
            title: 'Virtua Gym',
            icon: 'setting',
            children: [
              {
                path: '/ezvgactivities',
                icon: 'interaction',
                component: 'EzVgActivitiesContainer',
                title: 'Ez-Vg Activities',
              },
              {
                path: '/vgwebappsettings',
                component: 'VgAppSettingsContainer',
                title: 'Vg Webapp Settings',
                icon: 'setting',
              },
              {
                path: '/vglinkez',
                component: 'VgLinkEzContainer',
                title: 'Link with VG',
                icon: 'setting',
              },
              {
                path: '/barcode',
                component: 'BarcodeContainer',
                title: 'VG Barcode',
                icon: 'setting',
              },
            ],
          },
          {
            path: '/agenda',
            component: 'AgendaContainer',
            title: 'Agenda',
            icon: 'setting',
          },
          {
            path: '/settings',
            component: 'EmailSettingsContainer',
            title: 'Email Booking Settings',
            icon: 'setting',
          },
          {
            path: '/manualtasks',
            component: 'ManualTaskContainer',
            title: 'Manual Tasks Settings',
            icon: 'setting',
          },
        ]);
      } else {
        const routes = [...data.me.enabledRoutes];
        const temp: any = [
          {
            submenu: true,
            icon: 'setting',
            title: 'Virtua Gym',
            children: [],
          },
        ];
        routes.forEach(item => {
          if (item.title == null) {
            return;
          }
          if (item.title == 'Ez-Vg Activities' || item.title == 'VG Webapp Setings' || item.title == 'Link with VG' || item.title == 'VG Barcode') {
            temp[0].children.push(item);
          } else {
            temp.push(item);
          }
        });
        setMenu(temp);
      }
    }
  }, [data, loading]);

  let activeMenu = window.location.pathname.toString();
  const handleLogOut = () => {
    console.log('Logout Initiated......');
    removeToken();
    window.location.reload();
  };

  return (
    <StyledSidebar trigger={null} width={280} collapsible collapsed={collapse}>
      <StyledSidebarHeader style={{ padding: `${collapse ? '7px' : '5px 18px'}` }}>
        {/* <Link to="/newservice"> */}
        {collapse ? <Avatar size={64} icon="user" /> : <h1>Services</h1>}
        {/* </Link> */}
      </StyledSidebarHeader>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={[activeMenu]} defaultOpenKeys={['services_submenu']}>
        {menu &&
          menu.map((singleMenu, index) => {
            if (singleMenu.submenu) {
              if (singleMenu.children && singleMenu.children.length !== 0) {
                if (singleMenu.title !== null) {
                  return (
                    <SubMenu
                      key={singleMenu.title}
                      title={
                        <span>
                          <Icon type={singleMenu.icon} />
                          <span>{singleMenu.title}</span>
                        </span>
                      }
                    >
                      {singleMenu.children.map((item, index) => {
                        return (
                          <Menu.Item key={item.path}>
                            <Link to={item.path}>
                              <span>{item.title}</span>
                            </Link>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                }
              }
            } else {
              if (singleMenu.title !== null) {
                return (
                  <Menu.Item key={singleMenu.path}>
                    <Link to={singleMenu.path}>
                      <Icon type={singleMenu.icon} />
                      <span>{singleMenu.title}</span>
                    </Link>
                  </Menu.Item>
                );
              }
            }
          })}
        {/* <Menu.Item key="/">
          <Link to="/">
            <Icon type="snippets" />
            <span>Gates</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/flaggedbookings">
          <Link to="/flaggedbookings">
            <Icon type="book" />
            <span>Flagged Bookings</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="/settings">
          <Link to="/settings">
            <Icon type="setting" />
            <span>Settings</span>
          </Link>
        </Menu.Item> */}
        <Menu.Item key="logout" style={{ marginTop: '25px', color: '#e74c3c', fontSize: '20px' }} onClick={handleLogOut}>
          <Icon type="logout" />
          <span>Logout</span>
        </Menu.Item>
      </Menu>
    </StyledSidebar>
  );
};

export default Sidebar;
