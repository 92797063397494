import React, { useRef, useState } from 'react';
import { Button, Popconfirm, Icon, Input, DatePicker } from 'antd';
import AntdTable from 'component/elements';
import moment from 'moment';
import { useQuery } from '@apollo/react-hooks';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';

const VideoTable = ({ tableLoading, openVideoPlayerModal, openEditVideoModal, onDeleteVideo, videos, openVideoLogsModal }) => {
  const titleSearhInputRef = useRef<any>();
  const [trainers, setTrainers] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      setActivities(data.activities);
      // console.log('data.activities', data.activities);
    },
  });
  useQuery(TRAINERS_QUERY, {
    onCompleted: data => setTrainers(data.trainers),
  });

  /** video title search  */
  const getVideoTitleSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={titleSearhInputRef}
          placeholder={`Search Title`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleTitleSearch(selectedKeys, confirm, 'videoTitle')}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleTitleSearch(selectedKeys, confirm, 'videoTitle')}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleTitleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record.title.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => titleSearhInputRef.current.select());
      }
    },
  });
  const handleTitleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleTitleReset = clearFilters => {
    clearFilters();
  };

  /** Date filter */
  const getDateFilterProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          placeholder={`Filter Date`}
          onChange={val => setSelectedKeys(val ? [val] : [])}
          format="DD-MMM"
        />
        <Button
          type="primary"
          onClick={() => handleDateFilter(selectedKeys, confirm, dataIndex)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleDateReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="filter" theme="filled" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => moment(record[dataIndex]).format('DD-MM-YYYY').includes(moment(value).format('DD-MM-YYYY')),
  });
  const handleDateFilter = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleDateReset = clearFilters => {
    clearFilters();
  };

  const columns = [
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      render: record => (
        <div>
          <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
            <img
              alt="video preview"
              src={record.thumbnail || require('assets/panamthumbnail.png')}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = require('assets/panamthumbnail.png');
              }}
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
              draggable={false}
            />
          </div>
        </div>
      ),
    },
    {
      key: 'title',
      title: 'Title',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }} onClick={() => openVideoPlayerModal(record)}>
          {record.title}
        </Button>
      ),
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
      ...getVideoTitleSearchProps(),
    },
    {
      key: 'activity',
      title: 'Activity',
      render: record => {
        return <span>{(record.activity && record.activity.name) || 'activity'}</span>;
      },
      sorter: (a, b) => a.activity.name.length - b.activity.name.length,
      sortDirections: ['descend', 'ascend'],
      filters:
        activities &&
        activities.map(item => ({
          text: item.name,
          value: item.name,
        })),
      filterMultiple: true,
      onFilter: (value, record) => record.activity.name.indexOf(value) === 0,
    },
    {
      key: 'trainer',
      title: 'Trainer',
      render: record => {
        return <span>{(record.trainer && record.trainer.name) || 'trainer'}</span>;
      },
      sorter: (a, b) => a.trainer.name.length - b.trainer.name.length,
      sortDirections: ['descend', 'ascend'],
      filters:
        trainers &&
        trainers.map(item => ({
          text: item.name,
          value: item.name,
        })),
      filterMultiple: true,
      onFilter: (value, record) => record.trainer.name.indexOf(value) === 0,
    },
    {
      key: 'compressionStatus',
      title: 'Compression',
      render: record => (record.compressionStatus ? record.compressionStatus : 'none'),
    },
    {
      key: 'premiereAt',
      title: 'Premiere At',
      render: record => (record.premiereAt ? moment(record.premiereAt).format('Do MMMM, hh:mm a') : 'No Premiere'),
      sorter: (a, b) => new Date(b.premiereAt).getTime() - new Date(a.premiereAt).getTime(),
      sortDirections: ['descend', 'ascend'],
      ...getDateFilterProps('premiereAt'),
    },
    {
      key: 'createdAt',
      title: 'Created At',
      render: record => moment(record.createdAt).format('Do MMMM, hh:mm a'),
      sorter: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'ascend',
      ...getDateFilterProps('createdAt'),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button type="primary" onClick={() => openEditVideoModal(record)}>
              Edit
            </Button>
          </span>
          <span style={{ marginRight: 10 }}>
            <Button onClick={() => openVideoLogsModal(record)} type="primary">
              View Logs
            </Button>
          </span>
          <span>
            <Popconfirm title="Are you sure delete this video?" onConfirm={() => onDeleteVideo(record._id)} okText="Yes" cancelText="No">
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span>
        </>
      ),
    },
  ];
  return (
    <AntdTable loading={tableLoading} rowKey={record => record._id} columns={columns} dataSource={videos} pagination={true} scroll={{ x: true }} />
  );
};

export default VideoTable;
