import React, { useState, useCallback } from 'react';
import { Card, Row, Col, Upload, Button, Input, Form, Icon, Switch } from 'antd';
import AddActivityModal from 'component/AddActivityModal/AddActivityModal';
import AddTrainerModal from 'component/AddTrainerModal/AddTrainerModal';
import ActivityTable from 'component/ActivityTable/ActivityTable';
import TrainerTable from 'component/TrainerTable/TrainerTable';

const ActivityAndTrainerContainer = () => {
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [showAddTrainerModal, setShowAddTrainerModal] = useState(false);
  const [isCreateActivity, setIsCreateActivity] = useState(true);
  const [isCreateTrainer, setIsCreateTrainer] = useState(true);
  const [currentTrainer, setCurrentTrainer] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(false);

  const openAddTrainerModal = () => {
    setShowAddTrainerModal(true);
  };
  const openAddActivityModal = () => {
    setShowAddActivityModal(true);
  };

  const handleActivityModalCancel = () => {
    setIsCreateActivity(true);
    setCurrentActivity(false);
    setShowAddActivityModal(false);
  };
  const handleTrainerModalCancel = () => {
    setIsCreateTrainer(true);
    setCurrentTrainer(false);
    setShowAddTrainerModal(false);
  };

  const onEditActivity = (selectedActivity) => {
    setIsCreateActivity(false);
    setCurrentActivity(selectedActivity);
    openAddActivityModal();
  };
  const onEditTrainer = (selectedTrainer) => {
    setIsCreateTrainer(false);
    setCurrentTrainer(selectedTrainer);
    openAddTrainerModal();
  };

  return (
    <>
      <Row style={{ margin: '15px 0' }}>
        <Col offset={1} span={10}>
          <Button type="primary" onClick={openAddActivityModal}>
            Add New Activity
          </Button>
        </Col>
        <Col offset={1} span={10}>
          <Button type="primary" onClick={openAddTrainerModal}>
            Add New Trainer
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={10} offset={1}>
          <ActivityTable onEdit={onEditActivity} />
        </Col>
        <Col span={10} offset={1}>
          <TrainerTable onEdit={onEditTrainer} />
        </Col>
      </Row>
      <AddActivityModal
        modalVisible={showAddActivityModal}
        isCreate={isCreateActivity}
        currentItem={currentActivity}
        handleOk={handleActivityModalCancel}
        handleCancel={handleActivityModalCancel}
      />
      <AddTrainerModal
        modalVisible={showAddTrainerModal}
        isCreate={isCreateTrainer}
        currentItem={currentTrainer}
        handleOk={handleTrainerModalCancel}
        handleCancel={handleTrainerModalCancel}
      />
      {/* <AddTrainerModal /> */}
    </>
  );
};
export default ActivityAndTrainerContainer;
