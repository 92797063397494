import gql from 'graphql-tag';
import { FLAGGED_BOOKINGS_FRAGMENT } from '../flaggedBookingsFragment';

export const FLAGGED_BOOKINGS_QUERY = gql`
  query bookingFlags($paginationInput: PaginationInput, $bookingFlagFilterInput: BookingFlagFilterInput) {
    bookingFlags(paginationInput: $paginationInput, bookingFlagFilterInput: $bookingFlagFilterInput) {
      bookings {
        ...BookingFlag
      }
      pagination {
        page
        limit
        total
      }
    }
  }
  ${FLAGGED_BOOKINGS_FRAGMENT}
`;
