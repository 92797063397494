import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Row, Col, Button, Icon, Modal, Form, Input, InputNumber, message, AutoComplete, Switch, notification } from 'antd';
import { WrappedFormUtils, FormComponentProps } from 'antd/lib/form/Form';
import { GATES_QUERY } from 'apollo/gates/queries';
import { GATE_CREATE_MUTATION, GATE_UPDATE_MUTATION } from 'apollo/gates/mutations';
import { CREATE_ACTIVITY_MUTATION, UPDATE_ACTIVITY_MUTATION } from 'apollo/activity/mutations';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';

interface ActivityModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const AddActivityModalComponent: React.FC<ActivityModalProps> = ({ form, isCreate, currentItem, handleCancel, handleOk, modalVisible }) => {
  const [createActivity] = useMutation(CREATE_ACTIVITY_MUTATION, {
    update(cache, { data: { createActivity } }) {
      const cachedData: any = cache.readQuery({ query: ACTIVITIES_QUERY });
      cache.writeQuery({
        query: ACTIVITIES_QUERY,
        data: { activities: [...cachedData.activities, createActivity] },
      });
    },
    refetchQueries: ['activities'],
  });
  const [updateActivity] = useMutation(UPDATE_ACTIVITY_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [activityParam, setActivityParam] = useState({
    name: '',
  });

  useEffect(() => {
    if (currentItem) {
      setActivityParam({
        ...activityParam,
        name: currentItem.name,
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setActivityParam({
      name: '',
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        ...activityParam,
        name: values.name,
      };
      if (isCreate) {
        createActivity({
          variables: {
            input: {
              ...params,
            },
          },
        })
          .then((mutationResult) => {
            setIsLoading(false);
            notification.success({
              message: 'Activity Created',
              duration: 0,
              description: 'Activity was created successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch((e) => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Activity Create Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      } else {
        updateActivity({
          variables: {
            _id: currentItem._id,
            input: {
              ...params,
            },
          },
        })
          .then((updatedResult) => {
            setIsLoading(false);
            notification.success({
              message: 'Activity Update',
              duration: 0,
              description: 'Activity was updated successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch((e) => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Activity Update Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      }
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Activity' : 'Update Activity Detail'}
      footer={null}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: activityParam.name,
            rules: [
              {
                required: true,
                message: 'Please input the name of site!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        {/* <Form.Item label="Site Code">
          {getFieldDecorator('code', {
            initialValue: gatesParam.code,
            rules: [
              {
                required: true,
                message: 'Please input the code of site!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Current Load">
          {getFieldDecorator('currentLoad', {
            initialValue: gatesParam.currentLoad,
            rules: [
              {
                required: true,
                message: 'Please input the currentLoad of site!',
              },
            ],
          })(<InputNumber style={{ width: '100%' }} />)}
        </Form.Item>
        <Form.Item label="Current Load Locked">
          {getFieldDecorator('currentLoadLocked', {
            valuePropName: 'checked',
            initialValue: gatesParam.currentLoadLocked,
          })(<Switch />)}
        </Form.Item>
        <Form.Item label="Active">
          {getFieldDecorator('active', {
            valuePropName: 'checked',
            initialValue: gatesParam.active,
          })(<Switch />)}
        </Form.Item> */}

        <Row style={{ marginTop: '30px' }}>
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>
            {isCreate ? 'Add' : 'Update'}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
const AddActivityModal = Form.create<ActivityModalProps>()(AddActivityModalComponent);
export default AddActivityModal;
