import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Icon,
  Switch,
  message,
  Select,
  Tooltip,
  Tag,
  Popconfirm,
  notification,
} from "antd";
import { useQuery } from "@apollo/react-hooks";
import LoadingSpinner from "component/Loader";
import AntdTable from "component/elements";
import moment from "moment";
import AddEditEzVgActivityModal from "component/AddEditEzVgActivityModal/AddEditEzVgActivityModal";
import {
  EZVGACTIVITIES_QUERY,
  VGDEFAULTACTIVITIES_QUERY,
} from "apollo/activity/queries";

const EzVgActivitiesContainer: React.FC = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const [showAddEditUserModal, setShowAddEditUserModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<Object | null>(null);
  const [isCreate, setIsCreate] = useState(true);
  // Old - Now using workouts manullay
  const { loading, error, data } = useQuery(EZVGACTIVITIES_QUERY);
  // const { loading: vgLoading, error: vgError, data: vgData } = useQuery(
  //   VGDEFAULTACTIVITIES_QUERY
  // );
  if (loading) return <LoadingSpinner />;
  // if (vgLoading) return <LoadingSpinner />;

  // if (vgError || error) {
  //   return <div>Error!</div>;
  // }

  const openAddEditEzVgActivityModal = (selected = false) => {
    if (selected) setIsCreate(false);
    setCurrentItem(selected);
    setShowAddEditUserModal(true);
  };
  const closeAddEditEzVgActivityModal = () => {
    setIsCreate(true);
    setShowAddEditUserModal(false);
    setCurrentItem(null);
  };

  const columns = [
    {
      key: "ezAudience",
      title: "Audience",
      render: (record) => <span>{record.ezAudience}</span>,
      sorter: (a, b) => a.ezAudience.length - b.ezAudience.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "ezAudienceId",
      title: "Audience Id",
      render: (record) => <span>{record.ezAudienceId}</span>,
      sorter: (a, b) => a.ezAudienceId.length - b.ezAudienceId.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "vgActivity",
      title: "VG Activity",
      render: (record) => {
        return <span>{record.vgActivity}</span>;
      },
      sorter: (a, b) => a.vgActivity.length - b.vgActivity.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "vgId",
      title: "Workout Id",
      render: (record) => {
        return <span>{record.vgId}</span>;
      },
      sorter: (a, b) => a.vgId.length - b.vgId.length,
      sortDirections: ["descend", "ascend"],
    },

    {
      key: "createdAt",
      title: "Created At",
      render: (record) => moment(record.createdAt).format("Do MMMM, hh:mm a"),
      sorter: (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => openAddEditEzVgActivityModal(record)}
            >
              Edit
            </Button>
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <Row style={{ marginTop: "30px" }}>
        <Col span={2} offset={1}>
          <div>
            <Tooltip
              placement="bottom"
              title="Add new ez activity"
              key="Add new ez activity"
            >
              <Button
                type="primary"
                onClick={() => openAddEditEzVgActivityModal(false)}
              >
                Add New Activity
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <Col span={22} offset={1}>
          <AntdTable
            loading={tableLoading}
            pagination={false}
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={data.ezVgActivities}
          />
        </Col>
      </Row>
      <AddEditEzVgActivityModal
        isCreate={isCreate}
        modalVisible={showAddEditUserModal}
        currentItem={currentItem}
        handleCancel={closeAddEditEzVgActivityModal}
        handleOk={closeAddEditEzVgActivityModal}
        // vgActivities={vgData.vgDefaultActivities}
      />
    </>
  );
};
export default EzVgActivitiesContainer;
