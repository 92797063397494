import React from 'react'

interface Props {
    
}

const Error404 = (props: Props) => {
    return (
        <div>
            404
        </div>
    )
}

export default Error404
