import React, { useState, useRef } from 'react';
import AntdTable from 'component/elements';
import { Button, Input, Icon, DatePicker, TimePicker } from 'antd';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { ANALYTICS_VIDEO_REPORT_QUERY, ANALYTICS_VIDEO_REPORT_PERDAY_QUERY } from 'apollo/analyticsReport/queries';
import { useQuery } from '@apollo/react-hooks';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';

const filterDays = [
  {
    text: 'Sunday',
    value: 'Sun',
  },
  {
    text: 'Monday',
    value: 'Mon',
  },
  {
    text: 'Tuesday',
    value: 'Tue',
  },
  {
    text: 'Wednesday',
    value: 'Wed',
  },
  {
    text: 'Thursday',
    value: 'Thu',
  },
  {
    text: 'Friday',
    value: 'Fri',
  },
  {
    text: 'Saturday',
    value: 'Sat',
  },
];

const VideoReportTable = () => {
  const [data, setData] = useState(null);
  const [openTPicker, setOpenTPicker] = useState(false);
  const titleSearhInputRef = useRef<any>();
  const [trainers, setTrainers] = useState<any>(null);
  const [activities, setActivities] = useState<any>(null);
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      setActivities(data.activities);
      // console.log('data.activities', data.activities);
    },
  });
  useQuery(TRAINERS_QUERY, {
    onCompleted: data => setTrainers(data.trainers),
  });

  useQuery(ANALYTICS_VIDEO_REPORT_QUERY, {
    variables: { startDate: '2020-07-01', endDate: 'today' },
    onCompleted: data => {
      // console.log('data', data);
      setData(data.analyticsVideoReport);
    },
    onError: err => {
      console.log('err', err);
    },
  });

  /** video title search  */
  const getVideoTitleSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={titleSearhInputRef}
          placeholder={`Search Title`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleTitleSearch(selectedKeys, confirm, 'videoTitle')}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleTitleSearch(selectedKeys, confirm, 'videoTitle')}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleTitleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record.videoTitle.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => titleSearhInputRef.current.select());
      }
    },
  });
  const handleTitleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleTitleReset = clearFilters => {
    clearFilters();
  };

  /** Date filter */
  const getDateFilterProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <DatePicker
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          placeholder={`Filter Date`}
          onChange={val => setSelectedKeys(val ? [val] : [])}
          format="DD-MMM"
        />
        <Button
          type="primary"
          onClick={() => handleDateFilter(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleDateReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="filter" theme="filled" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => moment(record.uploadDate).format('DD-MMM').includes(moment(value).format('DD-MMM')),
  });
  const handleDateFilter = (selectedKeys, confirm) => {
    confirm();
  };
  const handleDateReset = clearFilters => {
    clearFilters();
  };

  /** Time filter */
  const getTimeFilterProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <TimePicker
          use12Hours
          open={openTPicker}
          onOpenChange={open => setOpenTPicker(open)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          value={selectedKeys[0]}
          placeholder={`Filter Time`}
          onChange={val => setSelectedKeys(val ? [val] : [])}
          format="h a"
          addon={() => (
            <Button size="small" type="primary" onClick={() => setOpenTPicker(false)}>
              Ok
            </Button>
          )}
        />
        <Button
          type="primary"
          onClick={() => handleTimeFilter(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleTimeReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="filter" theme="filled" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => moment(record.uploadDate).format('hh a').includes(moment(value).format('hh a')),
  });
  const handleTimeFilter = (selectedKeys, confirm) => {
    confirm();
  };
  const handleTimeReset = clearFilters => {
    clearFilters();
  };

  const columns = [
    {
      key: 'date',
      title: 'Date',
      width: 100,
      render: record => <span>{moment(record.uploadDate).format('DD-MMM')}</span>,
      sorter: (a, b) => moment(a.uploadDate).unix() - moment(b.uploadDate).unix(),
      sortDirections: ['descend', 'ascend'],
      ...getDateFilterProps(),
    },
    {
      key: 'videoTitle',
      title: 'Video Title',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.videoTitle}
        </Button>
      ),
      sorter: (a, b) => a.videoTitle.length - b.videoTitle.length,
      sortDirections: ['descend', 'ascend'],
      ...getVideoTitleSearchProps(),
    },
    {
      key: 'instructor',
      title: 'Instructor',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.instructor}
        </Button>
      ),
      filters:
        trainers &&
        trainers.map(item => ({
          text: item.name,
          value: item.name,
        })),
      filterMultiple: true,
      onFilter: (value, record) => record.instructor.indexOf(value) === 0,
      sorter: (a, b) => a.instructor.length - b.instructor.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'activity',
      title: 'Activity',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.activity}
        </Button>
      ),
      filters:
        activities &&
        activities.map(item => ({
          text: item.name,
          value: item.name,
        })),
      filterMultiple: true,
      onFilter: (value, record) => record.activity.indexOf(value) === 0,
      sorter: (a, b) => a.activity.length - b.activity.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'day',
      title: 'Day',
      width: 100,
      render: record => <span>{moment(record.uploadDate).format('ddd')}</span>,
      filters: filterDays,
      filterMultiple: true,
      onFilter: (value, record) => moment(record.uploadDate).format('ddd').indexOf(value) === 0,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'time',
      title: 'Time',
      width: 100,
      render: record => <span>{moment(record.uploadDate).format('hh a')}</span>,
      ...getTimeFilterProps(),
    },
    {
      key: 'videoStarts',
      title: 'Video Starts',
      width: 120,
      render: record => {
        return <span>{record.videoStarts}</span>;
      },
      sorter: (a, b) => a.videoStarts - b.videoStarts,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'videoCompletes',
      title: 'Video Completes',
      width: 125,
      render: record => {
        return <span>{record.videoCompletes}</span>;
      },
      sorter: (a, b) => a.videoCompletes - b.videoCompletes,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'avgVidTimeWatched',
      title: 'Avg. Video Time Watched',
      width: 150,
      render: record => {
        return <span>{new Date(record.avgVidTimeWatched * 1000).toISOString().substr(11, 8)}</span>;
      },
      sorter: (a, b) => a.avgVidTimeWatched - b.avgVidTimeWatched,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'totalLikes',
      title: 'Total Likes',
      width: 120,
      render: record => {
        return <span>{record.totalLikes}</span>;
      },
      sorter: (a, b) => a.totalLikes - b.totalLikes,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'totalDislikes',
      title: 'Total Dislikes',
      width: 120,
      render: record => {
        return <span>{record.totalDislikes}</span>;
      },
      sorter: (a, b) => a.totalDislikes - b.totalDislikes,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'totalComments',
      title: 'Total Comments',
      width: 130,
      render: record => {
        return <span>{record.totalComments}</span>;
      },
      sorter: (a, b) => a.totalComments - b.totalComments,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  if (!data && !trainers && !activities) {
    return (
      <div style={{ textAlign: 'center', margin: '50px 0' }}>
        <Loader type="TailSpin" color="#202541" />
      </div>
    );
  }

  return (
    <AntdTable
      // size="small"
      scroll={{ x: true }}
      pagination={true}
      rowKey={record => record.videoTitle + moment(record.uploadDate).unix()}
      columns={columns}
      dataSource={data}
    />
  );
};

export default VideoReportTable;
