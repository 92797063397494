import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import AntdTable from "../../../component/elements";
import LoadingSpinner from "../../../component/Loader";
import { GATES_QUERY } from "apollo/gates/queries";
import { GATE_DELETE_MUTATION } from "apollo/gates/mutations";
import {
  Row,
  Col,
  Tag,
  Button,
  Icon,
  Menu,
  Dropdown,
  Tooltip,
  message,
  Modal,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import AddEditModal from "./AddEditModal";
// import { WrappedFormUtils, FormComponentProps } from "antd/lib/form/Form";

// interface NewJobProps {
//   form: WrappedFormUtils;
// }

const GatesListContainer: React.FC = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [currentDeleteId, setCurrentDeleteId] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  useEffect(() => {
    if (currentDeleteId) {
      setTableLoading(true);
      deleteGate({
        variables: {
          id: currentDeleteId,
        },
      })
        .then(() => {
          setTableLoading(false);
          setCurrentDeleteId(null);
        })
        .catch((err) => {
          setTableLoading(false);
          setCurrentDeleteId(null);
          const errorMessage = err
            .toString()
            .replace("Error: GraphQL error:", "");
          message.error(errorMessage);
        });
    }
  }, [currentDeleteId]);

  const [deleteGate] = useMutation(GATE_DELETE_MUTATION, {
    update(cache) {
      const cachedData: any = cache.readQuery({ query: GATES_QUERY });
      cache.writeQuery({
        query: GATES_QUERY,
        data: {
          gates: cachedData.gates.filter(
            (singlegate) => singlegate._id !== currentDeleteId
          ),
        },
      });
    },
  });

  const { loading, error, data } = useQuery(GATES_QUERY);
  if (loading) return <LoadingSpinner />;

  const actions = [
    {
      confirm: false,
      title: "Edit",
      iconItem: (
        <>
          <Icon
            type="edit"
            style={{
              fontSize: "18px",
              margin: "0 10px 0 0",
            }}
          />
          {"Edit"}
        </>
      ),
      onClick: (record) => onRowClick(record),
    },
    {
      confirm: false,
      title: "Delete",
      iconItem: (
        <>
          <Icon
            type="delete"
            style={{
              fontSize: "18px",
              margin: "0 10px 0 0",
            }}
          />
          {"Delete"}
        </>
      ),
      onClick: (record) => onDeleteClick(record),
    },
  ];

  const StyledMenu = styled(Menu)`
    .ant-dropdown-menu-item {
      padding: 15px !important;
      direction: ${({ theme: locale }) => (locale === "ar" ? "rtl" : "ltr")};

      > span {
        font-size: 14px;
        letter-spacing: 0.8px;
        color: #555;

        i {
          margin: 0 8px;
        }
      }
    }
  `;

  const MenuItems = (item) => (
    <StyledMenu>
      {Array.isArray(actions) &&
        actions.map((action) => (
          <Menu.Item key={action.title}>
            <div
              onClick={(e) => {
                e.stopPropagation();
                action.onClick(item);
              }}
            >
              {action.iconItem}
            </div>
          </Menu.Item>
        ))}
    </StyledMenu>
  );

  const columns = [
    {
      key: "code",
      title: "Site Code",
      dataIndex: "code",
    },
    {
      key: "name",
      title: "Name",
      render: (record) => (
        <Button type="link" style={{ padding: "0", color: "#202541" }}>
          {record.name}
        </Button>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      key: "currentLoad",
      title: "Current Load",
      render: (record) => {
        return <Tag color="#1d89db">{record.currentLoad}</Tag>;
      },
      sorter: (a, b) => a.currentLoad - b.currentLoad,
    },
    {
      key: "currentLoadLocked",
      title: "Load Locked",
      render: (record) => {
        return (
          <Tag color="#1d89db">{record.currentLoadLocked ? "Yes" : "No"}</Tag>
        );
      },
    },
    {
      key: "active",
      title: "Status",
      render: (record) => {
        return (
          <Tag color={record.active ? "green" : "red"}>
            {record.active ? "Active" : "Inactive"}
          </Tag>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      render: (record) => moment(record.updatedAt).format("Do MMMM, hh:mm a"),
    },
    {
      render: (text, record) => {
        return (
          <Dropdown trigger={["hover"]} overlay={MenuItems(record)}>
            <Param className="ant-dropdown-link">
              <Icon type="ellipsis" />
            </Param>
          </Dropdown>
        );
      },
    },
  ];

  const toggleModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  const handleCancel = () => {
    setIsCreate(true);
    setCurrentItem(false);
    toggleModalVisible();
  };

  const onRowClick = (record) => {
    setCurrentItem(record);
    setIsCreate(false);
    toggleModalVisible();
  };

  const onDeleteClick = (record) => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${record.code} ?`,
      okText: "Ok",
      cancelText: "Cancel",
      onOk: () => setCurrentDeleteId(record._id),
    });
  };

  const handleAdd = () => {
    setCurrentItem(false);
    setIsCreate(true);
    toggleModalVisible();
  };

  const Param = styled.div`
    text-align: center;
    font-size: 29px;
    margin-bottom: 0;
    cursor: pointer;
  `;

  //   if (load) return <LoadingSpinner />;

  return (
    <>
      <Row style={{ marginTop: "30px" }}>
        <Col span={22} offset={1}>
          <div>
            <Tooltip placement="bottom" title="Add New" key="Add New">
              <Button type="primary" onClick={handleAdd}>
                Add
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <Col span={22} offset={1}>
          <AntdTable
            loading={tableLoading}
            pagination={false}
            rowKey={(record) => record._id}
            columns={columns}
            dataSource={data.gates}
          />
        </Col>
      </Row>
      <AddEditModal
        modalVisible={modalVisible}
        isCreate={isCreate}
        currentItem={currentItem}
        handleCancel={handleCancel}
        handleOk={handleCancel}
        gates={data && data.gates}
      />
    </>
  );
};

export default GatesListContainer;
