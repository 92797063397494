import axios from 'axios';

const STREAM_BASE_URL = 'https://antvideo.tpasc.ca:5443/LiveApp/rest/v2/';

const URLS = {
  CREATE_STREAM: `${STREAM_BASE_URL}broadcasts/create`,
  UPLOAD_VIDEO: `${STREAM_BASE_URL}vods/create`,
};

const createStream = () => {
  return axios.post(URLS.CREATE_STREAM, {
    name: 'rest test',
  });
};

const uploadVideo = async (url, file, onUploadProgress) => {
  let config = {
    method: 'put',
    // url: `${URLS.UPLOAD_VIDEO}?name=${title}.mp4`,
    url: url,
    // url: `https://4776f10116e6.ngrok.io/s/api/v1/videos/upload?name=${title}.mp4`,
    // url: `http://localhost:5000/s/api/v1/videos/upload?name=${title}.mp4`,
    onUploadProgress: onUploadProgress,
    headers: {
      'Content-Type': file.type,
    },
    data: file,
  };
  // console.log('config', config);
  return axios(config);
};

export default { createStream, uploadVideo };
