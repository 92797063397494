import React from 'react';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './globalStyles'

const App: React.FC = () => {

  return (
    <React.Fragment>
      <BrowserRouter>
      <GlobalStyles />
        <Router />
      </BrowserRouter>
    </React.Fragment>
  )
}

export default App
