import React, { useState, useCallback } from 'react';
import {
  Card,
  Row,
  Col,
  Upload,
  Button,
  Input,
  Form,
  Icon,
  Switch,
  message,
  Select,
  Tooltip,
  Tag,
  Popconfirm,
  notification,
  Calendar,
  Badge,
} from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { VIDEOS_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import AntdTable from 'component/elements';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DELETE_VIDEO_MUTATION } from 'apollo/video/mutations/deleteVideo';
import EditVideoModal from 'component/EditVideoModal/EditVideoModal';
import VideoTableModal from 'component/VideoTableModal/VideoTableModal';

interface CalendarCompProps {}

const CalendarContainer: React.FC<CalendarCompProps> = ({}) => {
  const history = useHistory();
  const [videos, setVideos] = useState<null | Array<Object>>(null);
  const [videosByDate, setVideosByDate] = useState<any>(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [showVideoTableModal, setShowVideoTableModal] = useState(false);
  const [deleteVideo] = useMutation(DELETE_VIDEO_MUTATION);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [videoList, setVideoList] = useState<any>(null);
  const { loading, error, data } = useQuery(VIDEOS_QUERY, {
    onCompleted: (data) => {
      let temp = {};
      data.videos.map((item) => {
        if (item.premiereAt && item.premiere) {
          if (!temp.hasOwnProperty(`${moment(item.premiereAt).format('DD MM YYYY')}`)) {
            temp[`${moment(item.premiereAt).format('DD MM YYYY')}`] = [];
          }
          temp[`${moment(item.premiereAt).format('DD MM YYYY')}`].push(item);
        } else {
          if (!temp.hasOwnProperty(`${moment(item.createdAt).format('DD MM YYYY')}`)) {
            temp[`${moment(item.createdAt).format('DD MM YYYY')}`] = [];
          }
          temp[`${moment(item.createdAt).format('DD MM YYYY')}`].push(item);
        }
      });
      // console.log('tempo', temp);
      setVideosByDate(temp);
      setVideos(data.videos);
      // console.log(data);
    },
  });
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('videos', data);
  // }

  // const openEditVideoModal = (selectedVideo) => {
  //   setCurrentItem(selectedVideo);
  //   setShowEditVideoModal(true);
  // };

  const getListData = (value) => {
    const keys = Object.keys(videosByDate);
    let temp: any = [];
    for (const key of keys) {
      if (value.format('DD MM YYYY') == key) {
        temp = videosByDate[key];
      }
    }
    // console.log('listdata', temp);
    return temp;
  };

  const dateCellRender = (value) => {
    if (videosByDate) {
      let count = 0;
      const listData = getListData(value);
      return (
        <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
          {listData.map((item) => {
            count++;
            if (count > 3) return null;
            if (count == 3) {
              return (
                <li>
                  <Badge color="#000" text="more..." />
                </li>
              );
            }
            if (count < 3) {
              return (
                <li key={item._id}>
                  <Badge color={item.premiere ? '#f50' : '#108ee9'} text={item.title} />
                </li>
              );
            }
          })}
        </ul>
      );
    } else {
      return <span></span>;
    }
  };
  const onDateSelect = (value) => {
    console.log('select date', value);
    if (videosByDate.hasOwnProperty(value.format('DD MM YYYY'))) {
      setVideoList(videosByDate[value.format('DD MM YYYY')]);
    } else {
      setVideoList([]);
    }
    setSelectedDate(value);
    setShowVideoTableModal(true);
  };

  const closeVideoTableModal = () => {
    setShowVideoTableModal(false);
    setVideoList(null);
  };

  return (
    <>
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          {/* <AntdTable loading={tableLoading} pagination={false} rowKey={(record) => record._id} columns={columns} dataSource={videos} /> */}
          <div style={{ display: 'flex' }}>
            <Badge color="#f50" text="Premiere Video" />
            <Badge color="#108ee9" text="Non-premiere Video" style={{ marginLeft: '15px' }} />
          </div>
          <div style={{ backgroundColor: '#fff' }}>
            <Calendar dateCellRender={dateCellRender} onSelect={onDateSelect} />
          </div>
        </Col>
      </Row>
      <VideoTableModal visible={showVideoTableModal} closeModal={closeVideoTableModal} videoList={videoList} selectedDate={selectedDate} />
      {/* <EditVideoModal modalVisible={showEditVideoModal} currentItem={currentItem} handleCancel={closeEditVideoModal} handleOk={closeEditVideoModal} /> */}
    </>
  );
};
export default CalendarContainer;
