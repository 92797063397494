import { gql } from 'apollo-boost';

export const GENERATE_PRE_SIGNED_URL_MUTATION = gql`
  mutation generatePreSignedURL($input: S3PresignedURLInput!) {
    generatePreSignedURL(input: $input) {
      type
      url
      vid
    }
  }
`;
