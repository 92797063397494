import { gql } from 'apollo-boost';
import { ACTIVITY_FRAGMENT } from '../activityFragment';

export const ACTIVITIES_QUERY = gql`
  query activities {
    activities {
      ...Activity
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
