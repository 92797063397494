import { gql } from 'apollo-boost';

export const ACTIVITY_FRAGMENT = gql`
  fragment Activity on Activity {
    _id
    name
    createdAt
    updatedAt
  }
`;
