import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Row, Col, Button, Icon, Modal, Form, Input, InputNumber, message, AutoComplete, Switch, notification } from 'antd';
import { WrappedFormUtils, FormComponentProps } from 'antd/lib/form/Form';

import { CREATE_TRAINER_MUTATION, UPDATE_TRAINER_MUTATION } from 'apollo/trainer/mutations';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';

interface TrainerModalProps extends FormComponentProps {
  isCreate: boolean;
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const AddTrainerModalComponent: React.FC<TrainerModalProps> = ({ form, isCreate, currentItem, handleCancel, handleOk, modalVisible }) => {
  const [createTrainer] = useMutation(CREATE_TRAINER_MUTATION, {
    update(cache, { data: { createTrainer } }) {
      const cachedData: any = cache.readQuery({ query: TRAINERS_QUERY });
      cache.writeQuery({
        query: TRAINERS_QUERY,
        data: { trainers: [...cachedData.trainers, createTrainer] },
      });
    },
    refetchQueries: ['trainers'],
  });
  const [updateTrainer] = useMutation(UPDATE_TRAINER_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [trainerParam, setTrainerParam] = useState({
    name: '',
    trainerPicUrl: '',
    trainerDetailUrl: '',
  });

  useEffect(() => {
    if (currentItem) {
      setTrainerParam((prev) => ({
        ...prev,
        name: currentItem.name,
        trainerPicUrl: currentItem.trainerPicUrl || '',
        trainerDetailUrl: currentItem.trainerDetailUrl || '',
      }));
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setTrainerParam({
      name: '',
      trainerPicUrl: '',
      trainerDetailUrl: '',
    });
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setIsLoading(true);
      const params = {
        ...trainerParam,
        name: values.name,
        trainerDetailUrl: values.trainerDetailUrl,
        trainerPicUrl: values.trainerPicUrl,
      };
      if (isCreate) {
        createTrainer({
          variables: {
            input: {
              ...params,
            },
          },
        })
          .then((mutationResult) => {
            setIsLoading(false);
            notification.success({
              message: 'Trainer Created',
              duration: 0,
              description: 'Trainer was created successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch((e) => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Trainer Create Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      } else {
        updateTrainer({
          variables: {
            _id: currentItem._id,
            input: {
              ...params,
            },
          },
        })
          .then((updatedResult) => {
            setIsLoading(false);
            notification.success({
              message: 'Trainer Updated',
              duration: 0,
              description: 'Trainer was updated successfully.',
            });
            resetForm();
            handleOk();
          })
          .catch((e) => {
            setIsLoading(false);
            const errorMessage = e.toString().replace('Error: GraphQL error:', '');
            notification.error({
              message: 'Trainer Update Failed',
              duration: 0,
              description: errorMessage,
            });
          });
      }
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Trainer' : 'Update Trainer Detail'}
      footer={null}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            initialValue: trainerParam.name,
            rules: [
              {
                required: true,
                message: 'Please input the name of trainer!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Trainer Detail Url">
          {getFieldDecorator('trainerDetailUrl', {
            initialValue: trainerParam.trainerDetailUrl,
            rules: [
              {
                type: 'url',
                message: 'Please input the correct url!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Trainer Picture Url">
          {getFieldDecorator('trainerPicUrl', {
            initialValue: trainerParam.trainerPicUrl,
            rules: [
              {
                type: 'url',
                message: 'Please input the correct url!',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Row style={{ marginTop: '30px' }}>
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>
            {isCreate ? 'Add' : 'Update'}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};
const AddTrainerModal = Form.create<TrainerModalProps>()(AddTrainerModalComponent);
export default AddTrainerModal;
