import { gql } from "apollo-boost";

export const EZVGACTIVITY_FRAGMENT = gql`
  fragment EzVgActivity on EzVgActivity {
    _id
    ezAudience
    ezAudienceId
    vgId
    vgActivity
    createdAt
    updatedAt
  }
`;
