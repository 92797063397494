import { gql } from 'apollo-boost';

export const VIDEO_FRAGMENT = gql`
  fragment Video on Video {
    _id
    isLive
    url
    thumbnail
    vid
    title
    description
    tags
    activity {
      _id
      name
    }
    trainer {
      _id
      name
    }
    premium
    premiere
    premiereAt
    totalViews
    duration
    intensity
    equipments
    allowComments
    compressionStatus
    mimeType
    rawMimeType
    videoLogs {
      _id
      createdAt
      message
      event
      extras
    }
    createdAt
    updatedAt
  }
`;
