import { gql } from 'apollo-boost';
import { ACTIVITY_FRAGMENT } from '../activityFragment';

export const ACTIVITY_QUERY = gql`
  query activity($_id: ID!) {
    activity(_id: $_id) {
      ...Activity
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
