import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Row, Col, Button, Icon, Modal, Form, Input, InputNumber, message, AutoComplete, Switch, notification, Divider } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';

import { USER_CREATE_MUTATION, USER_UPDATE_MUTATION } from 'apollo/user/mutations';
import { USERS_QUERY } from 'apollo/user/queries';
import viewsList from 'helper/viewsList';
import RouteSwitch from 'component/RouteSwitch/RouteSwitch';

interface UserAccessModalProps {
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}

const EditUserAccessModal: React.FC<UserAccessModalProps> = ({ currentItem, handleCancel, handleOk, modalVisible }) => {
  const [views, setViews] = useState(viewsList);
  const [userUpdate] = useMutation(USER_UPDATE_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [enabledRoutes, setEnabledRoutes] = useState<any>([]);

  useEffect(() => {
    if (currentItem) {
      setEnabledRoutes(currentItem.enabledRoutes);
    }
  }, [currentItem]);

  const handleCancelModal = () => {
    handleCancel();
  };
  const onChange = (val, view) => {
    let temp = [...enabledRoutes];
    if (val) {
      temp.push(view);
      setEnabledRoutes(temp);
    } else {
      let index = temp.findIndex(route => {
        // console.log('views.er', item, route);
        if (route.title == view.title) {
          return true;
        }
      });
      temp.splice(index, 1);
      setEnabledRoutes(temp);
    }
    userUpdate({
      variables: {
        _id: currentItem._id,
        input: {
          firstName: currentItem.firstName,
          lastName: currentItem.lastName,
          role: currentItem.role,
          email: currentItem.email,
          enabledRoutes: temp,
        },
      },
    })
      .then(data => console.log('mutation data', data))
      .catch(err => {
        const errorMessage = err.toString().replace('Error: GraphQL error:', '');
        message.error(errorMessage);
      });
  };
  const getChecked = item => {
    return enabledRoutes.findIndex(route => {
      // console.log('views.er', item, route);
      if (route.title == item.title) {
        return true;
      }
    });
  };

  const renderViewsList =
    enabledRoutes &&
    views.map((item, index) => {
      let checked = getChecked(item);
      return <RouteSwitch key={item.title} onChange={onChange} checked={checked > -1 ? true : false} view={item} />;
    });

  return (
    <Modal
      title={`User Access - ${currentItem && currentItem.firstName} ${currentItem && currentItem.lastName}`}
      footer={null}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      {renderViewsList}
    </Modal>
  );
};
export default EditUserAccessModal;
