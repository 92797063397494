import React, { useState, useRef } from 'react';
import AntdTable from '../../../component/elements';
import LoadingSpinner from '../../../component/Loader';
import { FLAGGED_BOOKINGS_QUERY } from 'apollo/flaggedBookings/queries';
import { BOOKING_FLAG_UPDATE_MUTATION, BOOKING_FLAG_DELETE_MUTATION, ENABLE_CLIENT_SELF_SERVICE_MUTATION } from 'apollo/flaggedBookings/mutations';
import { Row, Col, Tag, Button, Icon, Menu, Dropdown, Tooltip, message, Modal, Divider, Switch, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import moment from 'moment';
import { useQuery, useMutation } from '@apollo/react-hooks';

const PAGE_LIMIT = 50;

interface FlaggedBookingsData {
  bookingFlags: any;
  pagination: any;
}

interface FlaggedBookingVars {
  paginationInput: {
    limit: number;
    page: number;
  };
}

const FlaggedBookingsContainer: React.FC = () => {
  const [flaggedBookings, setFlaggedBookings] = useState([] as any);
  const [bookingFlagFilter, setBookingFlagFilter] = useState<any>({});
  const [paginationData, setPaginationData] = useState(false as any);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateBookingFlag] = useMutation(BOOKING_FLAG_UPDATE_MUTATION);
  const [deleteBookingFlag] = useMutation(BOOKING_FLAG_DELETE_MUTATION);
  const clientNameSearhInputRef = useRef<any>();

  const { fetchMore } = useQuery(FLAGGED_BOOKINGS_QUERY, {
    variables: {
      paginationInput: {
        limit: PAGE_LIMIT,
        page: currentPage,
      },
      bookingFlagFilterInput: bookingFlagFilter,
    },
    onCompleted: data => {
      if (data && data.bookingFlags) {
        setFlaggedBookings(data.bookingFlags.bookings);
        setPaginationData(data.bookingFlags.pagination);
        setLoading(false);
      }
    },
    onError: err => {
      setLoading(false);
      console.log('err are', err);
    },
  });

  const handleReset = (type, record, index) => {
    if (type == 'secondMail') {
      updateBookingFlag({
        variables: {
          id: record._id,
          input: {
            secondBookingDate: null,
            secondFlaggedMailSent: false,
            secondFlaggedMailSentDate: null,
          },
        },
      })
        .then(updatedResult => {
          const result: any = updatedResult;
          setLoading(false);
          const oldBookings = [...flaggedBookings];
          oldBookings[index] = { ...result.data.updateBookingFlag };
          setFlaggedBookings(oldBookings);
          message.success('Updated Successully');
        })
        .catch(e => {
          setLoading(false);
          const errorMessage = e.toString().replace('Error: GraphQL error:', '');
          message.error(errorMessage);
        });
    } else {
      deleteBookingFlag({
        variables: {
          id: record._id,
        },
      })
        .then(updatedResult => {
          setLoading(false);
          const oldBookings: any = flaggedBookings;
          setFlaggedBookings(oldBookings.filter(singleBook => singleBook._id !== record._id));
          message.success('Deleted Successully');
        })
        .catch(e => {
          setLoading(false);
          const errorMessage = e.toString().replace('Error: GraphQL error:', '');
          message.error(errorMessage);
        });
    }
  };

  /** flagged bookings client name search  */
  const getClientNameSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={clientNameSearhInputRef}
          placeholder={`Search Client Name`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleClientNameSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleClientNameSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleClientNameReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    // onFilter: (value, record) => record.ClientName.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => clientNameSearhInputRef.current.select());
      }
    },
  });
  const handleClientNameSearch = (selectedKeys, confirm) => {
    confirm();
  };
  const handleClientNameReset = clearFilters => {
    clearFilters();
  };

  if (loading) return <LoadingSpinner />;

  const columns = [
    {
      key: 'ClientID',
      title: 'Client Id',
      dataIndex: 'ClientID',
      width: 100,
    },
    {
      key: 'ClientName',
      title: 'Name',
      dataIndex: 'ClientName',
      width: 100,
      render: name => <a>{name}</a>,
      ...getClientNameSearchProps(),
    },
    {
      key: 'ClientEmail',
      title: 'Email',
      dataIndex: 'ClientEmail',
      width: 200,
    },
    {
      key: 'title',
      title: 'Title',
      dataIndex: 'title',
      width: 100,
    },
    {
      key: 'month',
      title: 'Month',
      dataIndex: 'month',
      width: 100,
    },
    {
      key: 'bookingStatus',
      title: 'Status',
      dataIndex: 'bookingStatus',
      width: 100,
    },
    {
      key: 'firstBookingDate',
      title: 'First Booked',
      width: 100,
      render: record => moment(record.firstBookingDate).format('Do MMMM, hh:mm a'),
    },
    {
      key: 'firstFlaggedMailSent',
      title: 'First Mail Sent',
      width: 100,
      render: record => (record.firstFlaggedMailSent ? 'Sent' : 'Not Sent'),
    },
    {
      key: 'firstFlaggedMailSentDate',
      title: 'First Mail Sent Date',
      width: 100,
      render: record => (record.firstFlaggedMailSentDate ? moment(record.firstFlaggedMailSentDate).format('Do MMMM, hh:mm a') : ''),
    },
    {
      key: 'secondBookingDate',
      title: 'Second Booked',
      width: 100,
      render: record => (record.secondBookingDate ? moment(record.secondBookingDate).format('Do MMMM, hh:mm a') : ''),
    },
    {
      key: 'secondFlaggedMailSent',
      title: 'Second Mail Sent',
      width: 100,
      render: record => (record.secondFlaggedMailSent ? 'Sent' : 'Not Sent'),
    },
    {
      key: 'secondFlaggedMailSentDate',
      title: 'Second Mail Sent Date',
      width: 100,
      render: record => (record.secondFlaggedMailSentDate ? moment(record.secondFlaggedMailSentDate).format('Do MMMM, hh:mm a') : ''),
    },
    {
      key: 'totalFlagged',
      title: 'Total Flagged',
      dataIndex: 'totalFlagged',
      width: 100,
    },
    {
      key: 'selfServiceBlocked',
      title: 'Self Service Blocked',
      render: record => <SelfServiceSwitch record={record} />,
      width: 150,
    },
    {
      key: 'remarks',
      title: 'Remarks',
      dataIndex: 'remarks',
      width: 150,
    },
    {
      title: 'Action',
      key: 'action',
      width: 200,
      render: (text, record, index) => (
        <span>
          <Button type="link" onClick={() => handleReset('secondMail', record, index)}>
            Reset Second Email
          </Button>
          <Divider type="vertical" />
          <Button type="link" onClick={() => handleReset('deleteMail', record, index)}>
            Delete
          </Button>
        </span>
      ),
    },
  ];
  //   if (load) return <LoadingSpinner />;

  const handleTableChange = (pagination, filters, sorter) => {
    // console.log('pagination', pagination);
    // console.log('filters', filters);
    // console.log('sorter', sorter);
    setCurrentPage(pagination.current);
    let paginationInput = {
      limit: PAGE_LIMIT,
      page: pagination.current,
    };
    let bookingFlagFilterInput = {};
    if (filters.ClientName && filters.ClientName.length) bookingFlagFilterInput['search'] = filters.ClientName[0];
    setBookingFlagFilter(bookingFlagFilterInput);
    fetch(paginationInput, bookingFlagFilterInput);
  };

  const fetch = (paginationInput, bookingFlagFilterInput: any = null) => {
    // console.log('paginationinput', paginationInput);
    // console.log('filtersinput', bookingFlagFilterInput);
    setTableLoading(true);
    fetchMore({
      variables: {
        paginationInput,
        ...(bookingFlagFilterInput && { bookingFlagFilterInput: bookingFlagFilterInput }),
      },
      updateQuery: (prev, { fetchMoreResult }: any) => {
        // console.log('fetchmore results', fetchMoreResult);
        if (!fetchMoreResult) return prev;
        setFlaggedBookings(fetchMoreResult.bookingFlags.bookings);
        setPaginationData(fetchMoreResult.bookingFlags.pagination);
        setTableLoading(false);
      },
    });
  };

  // const handlePageChange = (page, pageSize) => {
  //   setTableLoading(true);
  //   setCurrentPage(page);
  //   fetchMore({
  //     variables: {
  //       paginationInput: {
  //         limit: PAGE_LIMIT,
  //         page,
  //       },
  //     },
  //     updateQuery: (prev, { fetchMoreResult }: any) => {
  //       if (!fetchMoreResult) return prev;
  //       setFlaggedBookings(fetchMoreResult.bookingFlags.bookings);
  //       setTableLoading(false);
  //     },
  //   });
  // };

  return (
    <>
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}></Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <AntdTable
            loading={tableLoading}
            size="small"
            rowKey={record => record._id}
            columns={columns}
            dataSource={flaggedBookings}
            scroll={{ x: 1500, y: 1000 }}
            onChange={handleTableChange}
            pagination={{
              position: 'bottom',
              pageSize: PAGE_LIMIT,
              total: paginationData.total,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              // onChange: handlePageChange,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

const SelfServiceSwitch = ({ record }) => {
  const [loading, setLoading] = useState(false);
  const [isBlocked, setIsBlocked] = useState(record.isSelfServiceBlocked);
  const [enableCilentSelfService] = useMutation(ENABLE_CLIENT_SELF_SERVICE_MUTATION);
  const onSwitchChange = val => {
    setLoading(true);
    enableCilentSelfService({
      variables: {
        _id: record._id,
        enable: !val,
      },
    })
      .then(res => {
        setIsBlocked(val);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.log('err');
      });
  };

  return <Switch loading={loading} checked={isBlocked} onChange={onSwitchChange} />;
};

export default FlaggedBookingsContainer;
