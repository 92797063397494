import { gql } from 'apollo-boost';
import { ACTIVITY_FRAGMENT } from '../activityFragment';

export const UPDATE_ACTIVITY_MUTATION = gql`
  mutation updateActivity($_id: ID!, $input: ActivityInput!) {
    updateActivity(_id: $_id, input: $input) {
      ...Activity
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
