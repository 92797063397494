import { gql } from 'apollo-boost';
import { VIDEO_FRAGMENT } from './../videoFragmet';

export const UPDATE_VIDEO_MUTATION = gql`
  mutation updateVideo($_id: ID!, $input: VideoUpdateInput!) {
    updateVideo(_id: $_id, input: $input) {
      ...Video
    }
  }
  ${VIDEO_FRAGMENT}
`;
