import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import Loader from 'react-loader-spinner';
import { useLazyQuery } from '@apollo/react-hooks';
import { ANALYTICS_VIDEO_REPORT_PERDAY_QUERY, ANALYTICS_VIDEO_REPORT_PERMONTH_QUERY } from 'apollo/analyticsReport/queries';
import moment from 'moment';
import { Select, DatePicker, Button, notification } from 'antd';
const { Option } = Select;

const { MonthPicker } = DatePicker;

const MinutesPlayedLineChart = () => {
  const [minutesPlayedData, setMinutesPlayedData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [getType, setGetType] = useState('daily');
  const [startDate, setStartDate] = useState(moment().add('months', -1));
  const [endDate, setEndDate] = useState(moment());
  const [getMinutesPlayedPermonth] = useLazyQuery(ANALYTICS_VIDEO_REPORT_PERMONTH_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setFetching(false);
      setLoading(false);
      let labels: any = [];
      let minutesData: any = [];
      if (data.analyticsVideoReportPermonth) {
        data.analyticsVideoReportPermonth.forEach((item: any) => {
          labels.push(moment(item.date).format('MMM-YYYY'));
          minutesData.push(item.vidTimeWatched / 60);
        });
      }
      setMinutesPlayedData({
        labels: labels,
        datasets: [
          {
            label: 'Minutes played per month',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: minutesData,
          },
        ],
      });
    },
    onError: err => {
      console.log('err', err);
      setFetching(false);
      setLoading(false);
    },
  });
  const [getMinutesPlayedPerday] = useLazyQuery(ANALYTICS_VIDEO_REPORT_PERDAY_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setFetching(false);
      setLoading(false);
      let labels: any = [];
      let minutesData: any = [];
      if (data.analyticsVideoReportPerday) {
        data.analyticsVideoReportPerday.forEach((item: any) => {
          labels.push(moment(item.date).format('DD-MMM'));
          minutesData.push(item.vidTimeWatched / 60);
        });
      }
      setMinutesPlayedData({
        labels: labels,
        datasets: [
          {
            label: 'Minutes played per day',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: minutesData,
          },
        ],
      });
    },
    onError: err => {
      console.log('err', err);
      setFetching(false);
      setLoading(false);
    },
  });
  useEffect(() => {
    getMinutesPlayedPerday({ variables: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') } });
    return () => {};
  }, []);

  const handleChange = value => {
    setGetType(value);
  };
  const onStartChange = value => {
    setStartDate(value);
  };
  const onEndChange = value => {
    setEndDate(value);
  };

  const handleApply = () => {
    setFetching(true);
    if (getType === 'daily') {
      if (startDate > endDate) {
        setFetching(false);
        notification.error({
          message: 'Start Date Error',
          description: 'Start Date cannot be greated than end date.',
        });
        return;
      }
      getMinutesPlayedPerday({ variables: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') } });
    }
    if (getType === 'monthly') {
      if (startDate.startOf('month') > endDate.endOf('month')) {
        setFetching(false);
        notification.error({
          message: 'Start Date Error',
          description: 'Start Date cannot be greated than end date.',
        });
        return;
      }
      getMinutesPlayedPermonth({ variables: { startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') } });
    }
  };

  if (loading)
    return (
      <div style={{ textAlign: 'center', margin: '50px 0' }}>
        <Loader type="TailSpin" color="#202541" />
      </div>
    );
  return (
    <div>
      <div style={{ display: 'flex', margin: '15px 0', justifyContent: 'flex-end' }}>
        <Select value={getType} style={{ width: 120 }} onChange={handleChange}>
          <Option value="daily">Daily</Option>
          <Option value="monthly">Monthly</Option>
        </Select>
        <div style={{ margin: '0 20px' }}>
          {getType === 'daily' && (
            <>
              <DatePicker value={startDate} onChange={onStartChange} />
              <DatePicker value={endDate} onChange={onEndChange} />
            </>
          )}
          {getType === 'monthly' && (
            <>
              <MonthPicker value={startDate} onChange={onStartChange} />
              <MonthPicker value={endDate} onChange={onEndChange} />
            </>
          )}
        </div>
        <Button loading={fetching} onClick={handleApply}>
          Apply
        </Button>
      </div>
      <Line data={minutesPlayedData} />
    </div>
  );
};

export default MinutesPlayedLineChart;
