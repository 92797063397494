import React from 'react'
import { StyledHeader } from './HeaderStyles'
import { Row, Col, Icon, Button } from 'antd'

interface Props {
    toggleSidebar: Function;
    collapse: boolean;
}

const Header: React.FC<Props> = ({
    toggleSidebar,
    collapse
}) => {

    return (
        <StyledHeader>
            <Row>
                <Col span={12}>
                    <span>
                        <Button type="link" onClick={() => toggleSidebar(!collapse)}>
                            <Icon type={collapse ? 'menu-unfold' : 'menu-fold'} style={{ fontSize: '24px', color: '#444' }} />
                        </Button>
                    </span>
                    <span>
                        <Button type="link" onClick={() => document.body.requestFullscreen()}>
                            <Icon type="fullscreen" style={{ fontSize: '24px', color: '#444' }}  />
                        </Button>
                    </span>
                </Col>
            </Row>
        </StyledHeader>
    )
}

export default Header
