import { gql } from 'apollo-boost';
import { USER_FRAGMENT } from '../userfragment';

export const USERS_QUERY = gql`
  query users {
    users {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;
