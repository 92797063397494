import React from 'react';
import { Modal, Button } from 'antd';
import AntdTable from 'component/elements';
import moment from 'moment';
const VideoLogsModal = ({ visible, closeModal, video }) => {
  const columns = [
    {
      key: 'createdAt',
      title: 'Event Time',
      render: record => moment(record.createdAt).format('D-MMM-YY hh:mm:ss'),
      sorter: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
    },
    {
      key: 'event',
      title: 'Event',
      render: record => <span>{record.event}</span>,
    },
    {
      key: 'message',
      title: 'Message',
      render: record => <span>{record.message}</span>,
    },
    {
      key: 'extras',
      title: 'Extras',
      render: record => (
        <div>
          {record.extras &&
            Object.keys(record.extras).map(item => (
              <div>
                {item}: {record.extras[item]}
              </div>
            ))}
        </div>
      ),
    },
  ];
  return (
    <>
      {video && (
        <Modal visible={visible} footer={null} onCancel={closeModal} title={`${video.title} logs`} width={1000}>
          {video.videoLogs && video.videoLogs.length > 0 ? (
            <AntdTable pagination={false} rowKey={record => record._id} columns={columns} dataSource={video.videoLogs} />
          ) : (
            <div style={{ textAlign: 'center' }}>
              <span>No Available Logs</span>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default VideoLogsModal;
