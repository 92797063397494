import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Row,
  Col,
  Button,
  Icon,
  Modal,
  Form,
  Input,
  InputNumber,
  message,
  List,
  Typography,
  AutoComplete,
  Switch,
} from "antd";
import { FormComponentProps } from "antd/lib/form/Form";
import { SETTINGS_QUERY } from "apollo/settings/queries";
import { SET_SETTINGS_MUTATION } from "apollo/settings/mutations";
import { Editor } from "@tinymce/tinymce-react";

interface EmailSettingsProps extends FormComponentProps {}

const { TextArea } = Input;
const allowedTemplateVariables = [
  "firstName",
  "lastName",
  "bookedDay",
  "bookedTime",
  "title",
  "firstBookedDate",
  "firstBookedTime",
  "utilsAdditionalDays - will add 7 days to booked day when used",
  "MembershipNumber",
];

const EmailSettingsForm: React.FC<EmailSettingsProps> = ({ form }) => {
  const { getFieldDecorator } = form;
  const [emailSettings, setEmailSettings] = useState({
    _id: "",
    senderName: "",
    firstEmailTemplate: "",
    secondEmailTemplate: "",
    firstMailSubject: "",
    secondMailSubject: "",
    firstPushMessage: "",
    secondPushMessage: ""
  });
  const [loading, setLoading] = useState(true);

  useQuery(SETTINGS_QUERY, {
    onCompleted: (data) => {
      if (data.settings) {
        setEmailSettings(data.settings);
      }
      setLoading(false);
    },
    onError: (err) => {
      setLoading(false);
      console.log("err are", err);
    },
  });
  const [setSettings] = useMutation(SET_SETTINGS_MUTATION);

  const handleFirstEmailTemplateChange = (content, editor) => {
    form.setFieldsValue({
      firstEmailTemplate: content,
    });
  };

  const handleSecondEmailTemplateChange = (content, editor) => {
    form.setFieldsValue({
      secondEmailTemplate: content,
    });
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      setLoading(true);
      const params = {
        ...values,
      };
      if (emailSettings._id) {
        params["_id"] = emailSettings._id;
      }
      setSettings({
        variables: {
          input: {
            ...params,
          },
        },
      })
        .then((mutationResult) => {
          setLoading(false);
          message.success("Updated Succesfully.");
        })
        .catch((e) => {
          setLoading(false);
          const errorMessage = e
            .toString()
            .replace("Error: GraphQL error:", "");
          message.error(errorMessage);
        });
    });
  };
  return (
    <Row>
      <Col span={14} offset={1}>
        <div style={{ background: "#FFFFFF", padding: "20px" }}>
          <h2>Email Settings</h2>
          <Form layout="vertical">
            <Form.Item label="Sender Name">
              {getFieldDecorator("senderName", {
                initialValue: emailSettings.senderName,
                rules: [
                  {
                    required: true,
                    message: "Please input the name of Sender!",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="First Email Subject">
              {getFieldDecorator("firstMailSubject", {
                initialValue: emailSettings.firstMailSubject,
                rules: [
                  {
                    required: true,
                    message: "Subject to be sent for first time",
                  },
                ],
              })(
                <TextArea
                  placeholder={"First Email Subject"}
                  autoSize={{ minRows: 2 }}
                />
              )}
            </Form.Item>
            <Form.Item label="First Email Template">
              {getFieldDecorator("firstEmailTemplate", {
                initialValue: emailSettings.firstEmailTemplate,
                rules: [
                  {
                    required: true,
                    message: "Template to be sent for first time",
                  },
                ],
              })(
                <TextArea
                  placeholder={"First Email Template"}
                  autoSize={{ minRows: 4 }}
                  hidden
                />
              )}
              <Editor
                apiKey="g8pcbxaq97xje1m48yk41399qs3qx43dkfrktlpeyihtsj3i"
                initialValue={emailSettings.firstEmailTemplate}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleFirstEmailTemplateChange}
              />
            </Form.Item>
            <Form.Item label="First Push Message">
              {getFieldDecorator("firstPushMessage", {
                initialValue: emailSettings.firstPushMessage || "",
                rules: [
                  {
                    required: true,
                    message: "Push to be sent for first time",
                  },
                ],
              })(
                <TextArea
                  placeholder={"First Push Message"}
                  autoSize={{ minRows: 2 }}
                />
              )}
            </Form.Item>
            <Form.Item label="Second Email Subject">
              {getFieldDecorator("secondMailSubject", {
                initialValue: emailSettings.secondMailSubject,
                rules: [
                  {
                    required: true,
                    message: "Subject to be sent for second time",
                  },
                ],
              })(
                <TextArea
                  placeholder={"Second Email Subject"}
                  autoSize={{ minRows: 2 }}
                />
              )}
            </Form.Item>
            <Form.Item label="Second Email Template">
              {getFieldDecorator("secondEmailTemplate", {
                initialValue: emailSettings.secondEmailTemplate,
                rules: [
                  {
                    required: true,
                    message: "Template to be sent for second time",
                  },
                ],
              })(
                <TextArea
                  placeholder={"Second Email Template"}
                  autoSize={{ minRows: 4 }}
                  hidden
                />
              )}
              <Editor
                apiKey="g8pcbxaq97xje1m48yk41399qs3qx43dkfrktlpeyihtsj3i"
                initialValue={emailSettings.secondEmailTemplate}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help",
                }}
                onEditorChange={handleSecondEmailTemplateChange}
              />
            </Form.Item>
            <Form.Item label="Second Push Message">
              {getFieldDecorator("secondPushMessage", {
                initialValue: emailSettings.secondPushMessage || "",
                rules: [
                  {
                    required: true,
                    message: "Push to be sent for second time",
                  },
                ],
              })(
                <TextArea
                  placeholder={"Second Push Message"}
                  autoSize={{ minRows: 2 }}
                />
              )}
            </Form.Item>
          </Form>
          <Row style={{ marginTop: "30px" }}>
            <Button type="primary" onClick={handleSubmit} loading={loading}>
              Update
            </Button>
          </Row>
        </div>
      </Col>
      <Col span={8} offset={1}>
        <div style={{ background: "#FFFFFF", padding: "20px" }}>
          <h2>Allowed Template Variables</h2>
          <div>
            <List
              size="small"
              bordered
              dataSource={allowedTemplateVariables}
              renderItem={(item) => (
                <List.Item>
                  <Typography.Text mark>{item}</Typography.Text>
                </List.Item>
              )}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};
const EmailSettingsContainer = Form.create<EmailSettingsProps>()(
  EmailSettingsForm
);
export default EmailSettingsContainer;
