import React, { useState, useCallback, useEffect } from 'react';
import { Card, Row, Col, Upload, Button, Input, Form, Icon, Switch, message, Select, Tooltip, Tag, Popconfirm, notification } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { VIDEOS_QUERY } from 'apollo/video/queries';
import LoadingSpinner from 'component/Loader';
import AntdTable from 'component/elements';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DELETE_VIDEO_MUTATION } from 'apollo/video/mutations/deleteVideo';
import EditVideoModal from 'component/EditVideoModal/EditVideoModal';
import VideoPlayerModal from 'component/VideoPlayerModal/VideoPlayerModal';
import VideoTable from 'component/VideoTable/VideoTable';
import VideoLogsModal from 'component/VideoLogsModal/VideoLogsModal';

interface VideoUploadProps {}

const VideosContainer: React.FC<VideoUploadProps> = ({}) => {
  const history = useHistory();
  const [videos, setVideos] = useState<null | Array<Object>>(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [showVideoLogsModal, setShowVideoLogsModal] = useState(false);
  const [showEditVideoModal, setShowEditVideoModal] = useState(false);
  const [showVideoPlayerModal, setShowVideoPlayerModal] = useState(false);
  const [deleteVideo] = useMutation(DELETE_VIDEO_MUTATION);
  const [currentItem, setCurrentItem] = useState<Object | null>(null);
  const { loading, error, data } = useQuery(VIDEOS_QUERY);
  // , {
  //   onCompleted: res => {
  //     setVideos(res.videos);
  //     console.log('videos data', res);
  //   },
  //   onError: err => console.log(err),
  // });
  useEffect(() => {
    if (data) {
      setVideos(data.videos);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  // if (data) {
  //   console.log('videos', data.videos);
  // }

  const openEditVideoModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowEditVideoModal(true);
  };
  const closeEditVideoModal = () => {
    setCurrentItem(null);
    setShowEditVideoModal(false);
  };

  const openVideoPlayerModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowVideoPlayerModal(true);
  };
  const closeVideoPlayerModal = () => {
    setCurrentItem(null);
    setShowVideoPlayerModal(false);
  };

  const openVideoLogsModal = selectedVideo => {
    setCurrentItem(selectedVideo);
    setShowVideoLogsModal(true);
  };
  const closeVideoLogsModal = () => {
    setCurrentItem(null);
    setShowVideoLogsModal(false);
  };

  const handleGoliveUpload = goLive => {
    history.push({
      pathname: 'videoUpload',
      search: `?goLive=${goLive ? '1' : '0'}`,
      state: { goLive: goLive },
    });
  };
  const onDeleteVideo = id => {
    message.loading({ content: 'Deleting video.', key: 'deleteMessage', duration: 0 });
    setTableLoading(true);
    deleteVideo({ variables: { _id: id } })
      .then(data => {
        if (videos) {
          let temp = videos;
          let index = temp.findIndex((i: any) => i._id == id);
          temp.splice(index, 1);
          setVideos(temp);
        }
        message.destroy();
        setTableLoading(false);
        notification.success({
          message: 'Video Deleted',
          duration: 0,
          description: 'Video was deleted successfully.',
        });
      })
      .catch(err => {
        message.destroy();
        setTableLoading(false);
        notification.error({
          message: 'Video Delete Failed',
          duration: 0,
          description: 'Video was not deleted.',
        });
      });
  };

  return (
    <>
      <Row style={{ marginTop: '30px' }}>
        <Col span={2} offset={1}>
          <div>
            <Tooltip placement="bottom" title="Live Stream" key="Live Stream">
              <Button type="primary" onClick={() => handleGoliveUpload(true)}>
                Go Live
              </Button>
            </Tooltip>
          </div>
        </Col>
        <Col span={2} offset={1}>
          <div>
            <Tooltip placement="bottom" title="Upload New Video" key="Upload New Video">
              <Button type="primary" onClick={() => handleGoliveUpload(false)}>
                Upload
              </Button>
            </Tooltip>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '30px' }}>
        <Col span={22} offset={1}>
          <VideoTable
            onDeleteVideo={onDeleteVideo}
            videos={videos}
            tableLoading={tableLoading}
            openVideoPlayerModal={openVideoPlayerModal}
            openEditVideoModal={openEditVideoModal}
            openVideoLogsModal={openVideoLogsModal}
          />
        </Col>
      </Row>
      <EditVideoModal modalVisible={showEditVideoModal} currentItem={currentItem} handleCancel={closeEditVideoModal} handleOk={closeEditVideoModal} />
      <VideoPlayerModal visible={showVideoPlayerModal} video={currentItem} closeModal={closeVideoPlayerModal} />
      <VideoLogsModal visible={showVideoLogsModal} video={currentItem} closeModal={closeVideoLogsModal} />
    </>
  );
};
export default VideosContainer;
