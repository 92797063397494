import { gql } from "apollo-boost";
import { GATES_FRAGMENT } from "../gatesFragment";

export const GATE_CREATE_MUTATION = gql`
  mutation createGate($input: GateCreateInput!) {
    createGate(input: $input) {
      ...Gate
    }
  }
  ${GATES_FRAGMENT}
`;
