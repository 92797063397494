import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Select, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import ColorPickerPopup from 'component/ColorPickerPopup/ColorPickerPopup';

interface AddEditTrainerHubButtonModalProps extends FormComponentProps {
  currentItem: any;
  isCreate: boolean;
  onCancel: any;
  onAdd: any;
  onEdit: any;
  visible: boolean;
}

const AddEditTrainerHubButtonModalComponent: React.FC<AddEditTrainerHubButtonModalProps> = ({
  form,
  isCreate,
  currentItem,
  onCancel,
  onAdd,
  onEdit,
  visible,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trainerHubButtonParam, setTrainerHubButtonParam] = useState<any>({
    link: undefined,
    color: undefined,
    label: undefined,
  });

  useEffect(() => {
    if (currentItem) {
      setTrainerHubButtonParam({
        ...trainerHubButtonParam,
        link: currentItem.link,
        color: currentItem.color,
        label: currentItem.label,
      });
    }
  }, [currentItem]);

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setTrainerHubButtonParam({
      link: undefined,
      color: undefined,
      label: undefined,
    });
  };

  const handleCancelModal = () => {
    resetForm();
    onCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      const trainerHubButton = {
        label: values.label,
        link: values.link,
        color: values.color,
      };
      if (isCreate) {
        onAdd(trainerHubButton);
      } else {
        onEdit(trainerHubButton);
      }
      resetForm();
    });
  };
  return (
    <Modal
      title={isCreate ? 'Add New Trainer Hub Button' : 'Edit Trainer Hub Button'}
      footer={[
        <Button key="buton" type="primary" onClick={handleSubmit} loading={isLoading}>
          {isCreate ? 'Add' : 'Update'}
        </Button>,
      ]}
      visible={visible}
      onCancel={handleCancelModal}
    >
      <Form layout="vertical">
        <Form.Item label="Label">
          {getFieldDecorator('label', {
            initialValue: trainerHubButtonParam.label,
            rules: [
              {
                required: true,
                message: 'Please enter Label!',
              },
            ],
          })(<Input placeholder="Button Label" />)}
        </Form.Item>
        <Form.Item label="Link">
          {getFieldDecorator('link', {
            initialValue: trainerHubButtonParam.link,
            rules: [
              {
                required: true,
                message: 'Please enter Link!',
              },
            ],
          })(<Input placeholder="Button Link" />)}
        </Form.Item>
        <Form.Item label="Color">
          {getFieldDecorator('color', {
            initialValue: trainerHubButtonParam.color,
            rules: [
              {
                required: true,
                message: 'Please enter Color!',
              },
            ],
          })(
            <ColorPickerPopup
              onChange={val => {
                console.log(val);
              }}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const AddEditTrainerHubButtonModal = Form.create<AddEditTrainerHubButtonModalProps>()(AddEditTrainerHubButtonModalComponent);
export default AddEditTrainerHubButtonModal;
