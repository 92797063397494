import React, { useState } from 'react';
import AntdTable from 'component/elements';
import { Button } from 'antd';
import AddEditTrainerHubButton from 'component/AddEditTrainerHubButton/AddEditTrainerHubButton';

const TrainerHubButtonTable = ({ trainerHubButtons, onAdd, onDelete, onEdit }) => {
  const [showAddEditTrainerHubButtonModal, setShowAddEditTrainerHubButtonModal] = useState(false);
  const [isCreate, setIsCreate] = useState(true);
  const [selectedTrainerHubButton, setSelectedTrainerHubButton] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<any>(null);

  const columns = [
    {
      key: 'label',
      title: 'Label',
      render: record => record.label,
    },
    {
      key: 'link',
      title: 'Link',
      render: record => record.link,
    },
    // {
    //   key: 'color',
    //   title: 'Color',
    //   render: record => record.color,
    // },
    {
      key: 'actions',
      title: 'Actions',
      render: (text, record, index) => (
        <>
          <span style={{ marginRight: 10 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectedTrainerHubButton(record);
                setSelectedIndex(index);
                setIsCreate(false);
                setShowAddEditTrainerHubButtonModal(true);
              }}
            >
              Edit
            </Button>
          </span>
          <span>
            <Button type="danger" onClick={() => onDelete(record, index)}>
              Delete
            </Button>
          </span>
        </>
      ),
    },
  ];

  const closeAddEditTrainerHubButtonModal = () => {
    setSelectedTrainerHubButton(null);
    setSelectedIndex(null);
    setIsCreate(true);
    setShowAddEditTrainerHubButtonModal(false);
  };

  const onAddTrainerHubButton = trainerHubButton => {
    onAdd(trainerHubButton);
    closeAddEditTrainerHubButtonModal();
  };
  const onEditTrainerHubButton = trainerHubButton => {
    onEdit(trainerHubButton, selectedIndex);
    closeAddEditTrainerHubButtonModal();
  };

  return (
    <div>
      <Button type="primary" style={{ marginBottom: 10 }} onClick={() => setShowAddEditTrainerHubButtonModal(true)}>
        Add Trainer Hub Button
      </Button>
      <AntdTable
        pagination={false}
        rowKey={record => `${record.bubbleType}${record.bubbleType == 'tag' ? record.tag : record.bubbleContent?._id}`}
        columns={columns}
        dataSource={trainerHubButtons}
        size="small"
      />
      <AddEditTrainerHubButton
        visible={showAddEditTrainerHubButtonModal}
        currentItem={selectedTrainerHubButton}
        isCreate={isCreate}
        onAdd={onAddTrainerHubButton}
        onEdit={onEditTrainerHubButton}
        onCancel={closeAddEditTrainerHubButtonModal}
      />
    </div>
  );
};

export default TrainerHubButtonTable;
