import { gql } from "apollo-boost";

export const GATES_FRAGMENT = gql`
  fragment Gate on Gate {
    _id
    name
    code
    currentLoad
    currentLoadLocked
    active
    threshold {
      _id
      min
      max
      label
      color
    }
    createdAt
    updatedAt
  }
`;
