import React, { useState } from 'react';
import { Form, Modal, Input, notification, Icon } from 'antd';
import AntdButton from 'component/elements/AntdButton';
import { useMutation } from '@apollo/react-hooks';
import { CHECK_EMAIL_FORGOT_PW_DASHBOARD_MUTATION } from 'apollo/user/mutations';
import { FormComponentProps } from 'antd/lib/form';

interface Props extends FormComponentProps {
  visible: boolean;
  onDone: any;
}

const ForgotPwForm = ({ visible, onDone, form }: Props) => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState<string | null>(null);
  const [checkEmailForgotPassword] = useMutation(CHECK_EMAIL_FORGOT_PW_DASHBOARD_MUTATION);

  const closeAndReset = () => {
    form.setFields({ email: undefined });
    setErr(null);
    onDone();
  };

  const handleSubmit = () => {
    setLoading(true);
    setErr(null);
    form.validateFields((err, values) => {
      if (err) {
        setLoading(false);
      }
      if (!err) {
        checkEmailForgotPassword({
          variables: {
            email: values.email,
          },
        })
          .then(async result => {
            if (result.data && result.data.checkEmailForgotPasswordDashboard) {
              const { type, payload } = result.data.checkEmailForgotPasswordDashboard;
              if (type == 'url') {
                if (payload) {
                  window.location.assign(payload);
                } else {
                  notification.error({
                    message: type,
                    description: 'No Url provided by location. Contact Your location.',
                  });
                }
              } else if (type == 'message') {
                notification.info({
                  message: type,
                  description: payload,
                });
                closeAndReset();
              }
            }
            setLoading(false);
          })
          .catch(error => {
            if (error.graphQLErrors && error.graphQLErrors.length) {
              const errMessage = error.graphQLErrors[0].message;
              setErr(errMessage);
              setLoading(false);
            }
          });
      }
    });
  };
  return (
    <Modal
      visible={visible}
      title="Forgot Password?"
      onCancel={closeAndReset}
      footer={[
        <AntdButton key="submit" type="primary" loading={loading} onClick={handleSubmit}>
          Submit
        </AntdButton>,
      ]}
    >
      <div>
        <p>Enter your registerd email address</p>
      </div>
      <p style={{ color: '#f00', marginBottom: '10px' }}>
        <strong>{err}</strong>
      </p>
      <Form layout="vertical" form={form} name="email">
        <Form.Item label="Email" labelAlign="left">
          {form.getFieldDecorator('email', {
            rules: [{ required: true, message: 'Enter your Email' }],
          })(<Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};
const ForgotPwModal = Form.create<Props>()(ForgotPwForm);

export default ForgotPwModal;
