export default [
  {
    path: '/',
    component: 'GatesListContainer',
    title: 'Gates',
    icon: 'snippets',
  },
  {
    path: '/flaggedBookings',
    component: 'FlaggedBookingsContainer',
    title: 'Flagged Bookings',
    icon: 'book',
  },
  {
    path: '/users',
    component: 'UsersContainer',
    title: 'Users And Roles',
    icon: 'user-add',
  },
  {
    path: '/ezvgactivities',
    component: 'EzVgActivitiesContainer',
    title: 'Ez-Vg Activities',
  },
  {
    path: '/vgwebappsettings',
    component: 'VgAppSettingsContainer',
    title: 'VG Webapp Setings',
  },
  {
    path: '/barcode',
    component: 'BarcodeContainer',
    title: 'VG Barcode',
  },
  {
    path: '/vglinkez',
    component: 'VgLinkEzContainer',
    title: 'Link with VG',
  },
  {
    path: '/agenda',
    component: 'AgendaContainer',
    title: 'Agenda',
    icon: 'setting',
  },
  {
    path: '/settings',
    component: 'EmailSettingsContainer',
    title: 'Email Booking Settings',
    icon: 'setting',
  },
  {
    path: '/manualtasks',
    component: 'ManualTaskContainer',
    title: 'Manual Tasks Settings',
    icon: 'setting',
  },
];
