import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Layout from '../../component/Layout';
import { Switch, Route } from 'react-router-dom';
import NewJob from '../../view/Main/add_services';
import Job from '../../view/Main/services';
import AgendaRoutes from '../../view/Main/agendas/AgendaRoutes';
import Error404 from '../Error404';
import ErrorLog from '../../view/Main/errorLog';
import GatesListContainer from 'view/Main/Gates';
import FlaggedBookingsContainer from 'view/Main/FlaggedBookings';
import EmailSettingsContainer from 'view/Main/Settings/EmailSettings';
import { ME_QUERY } from 'apollo/user/queries';
import LoadingSpinner from 'component/Loader';
import VideoUploadContainer from 'view/Main/videoUpload';
import ActivityAndTrainerContainer from 'view/Main/activityAndTrainer';
import VideosContainer from 'view/Main/videos';
import CalendarContainer from 'view/Main/Calendar';
import UsersContainer from 'view/Main/Users/Users';
import AnalyticsContainer from 'view/Main/analytics/analytics';
import EzVgActivitiesContainer from 'view/Main/EzVgActivities';
import VgAppSettingsContainer from 'view/Main/vgAppSettings';
import VgLinkEzContainer from 'view/Main/VgLinkEz';
import AgendaContainer from 'view/Main/Agenda';
import BarcodeContainer from 'view/Main/Barcode';
import ManualTaskContainer from 'view/Main/manualtask/manualtask';
import { message } from 'antd';

interface Props { }

const AllComponents = {
  VideosContainer,
  VideoUploadContainer,
  ActivityAndTrainerContainer,
  FlaggedBookingsContainer,
  EmailSettingsContainer,
  ErrorLog,
  Error404,
  GatesListContainer,
  CalendarContainer,
  UsersContainer,
  AnalyticsContainer,
  EzVgActivitiesContainer,
  VgAppSettingsContainer,
  VgLinkEzContainer,
  AgendaContainer,
  BarcodeContainer,
  ManualTaskContainer
};

const AdminRouterDefs = () => {
  return (
    <Switch>
      {/* <Route path="/videos" component={VideosContainer} />
      <Route path="/videoupload" component={VideoUploadContainer} />
      <Route path="/activityandtrainer" component={ActivityAndTrainerContainer} /> */}
      <Route path="/flaggedbookings" component={FlaggedBookingsContainer} />
      {/* <Route path="/calendar" component={CalendarContainer} /> */}
      <Route path="/settings" component={EmailSettingsContainer} />
      <Route path="/users" component={UsersContainer} />
      {/* <Route path="/analytics" component={AnalyticsContainer} /> */}
      <Route path="/ezvgactivities" component={EzVgActivitiesContainer} />
      <Route path="/vgwebappsettings" component={VgAppSettingsContainer} />
      <Route path="/error_logs" component={ErrorLog} />
      <Route path="/vglinkez" component={VgLinkEzContainer} />
      <Route path="/agenda" component={AgendaContainer} />
      <Route path="/manualtasks" component={ManualTaskContainer} />
      <Route path="/barcode" component={BarcodeContainer} />
      <Route exact path="/" component={GatesListContainer} />
      <Route component={Error404} />
    </Switch>
  );
};

// const StaffRouterDefs = () => {
//   return (
//     <Switch>
//       <Route path="/settings" component={EmailSettingsContainer} />
//       <Route path="/error_logs" component={ErrorLog} />
//       <Route exact path="/" component={FlaggedBookingsContainer} />
//       <Route component={Error404} />
//     </Switch>
//   );
// };

const ProtectedRoutes = (props: Props) => {
  const [skip, setSkip] = useState(false);
  const [menu, setMenu] = useState([]);

  const { loading, error, data } = useQuery(ME_QUERY, { skip });

  const makeRoutes = enabledRoutes => {
    const temp = [...enabledRoutes];
    const rootPaths = enabledRoutes && enabledRoutes.length > 0 && enabledRoutes.filter(item => item.path == '/');
    if (temp.length > 0 && rootPaths.length == 0) {
      temp[0].path = '/';
    }
    return (
      <Switch>
        {temp.map((route, index) => (
          <Route key={route.title} path={route.path} exact component={AllComponents[route.component]} />
        ))}
        <Route component={Error404} />
      </Switch>
    );
  };
  // useEffect(() => {
  //   console.log("-----Protected Routes-----", data);
  //   if (!loading && !!data) {
  //     console.log("--data---", data);
  //     // setSkip(true);
  //   }
  // }, [data, loading]);

  if (loading) return <LoadingSpinner />;
  // if (data) console.log('data', data);
  if (error) return `Problem`;

  if (data.me.disabled) {
    return message.error('Unauthorized access');
  }
  return (
    <Layout menu={menu}>
      {data.me.role == 'admin' && AdminRouterDefs()}
      {data.me.role == 'staff' && data.me.enabledRoutes && makeRoutes(data.me.enabledRoutes)}
      {/*{data.me.role == 'staff' && StaffRouterDefs()} */}
    </Layout>
  );
};

export default ProtectedRoutes;
