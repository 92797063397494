import { gql } from "apollo-boost";

export const SETTINGS_FRAGMENT = gql`
  fragment Settings on Settings {
    _id
    senderName
    firstEmailTemplate
    secondEmailTemplate
    firstMailSubject
    secondMailSubject
    createdAt
    updatedAt
    firstPushMessage
    secondPushMessage
  }
`;
