import React from 'react';
import { Popover } from 'antd';
import { SketchPicker } from 'react-color';

const ColorPickerPopup = ({ value = '#fff', onChange }) => {
  const content = <SketchPicker onChangeComplete={val => onChange(val.hex)} disableAlpha={true} color={value} width={300} />;
  return (
    <Popover placement="topLeft" title="Pick a color" content={content} trigger="click">
      <div style={{ background: value, width: 50, height: 30, borderRadius: 5, border: '1px solid #D9D9D9' }}></div>
    </Popover>
  );
};

export default ColorPickerPopup;
