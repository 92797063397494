import gql from "graphql-tag";
import { USER_FRAGMENT } from "../userfragment";

export const ME_QUERY = gql`
  query me {
    me {
      ...User
    }
  }
  ${USER_FRAGMENT}
`;
