import React from 'react';
import { Modal, Button } from 'antd';
import AntdTable from 'component/elements';
import moment from 'moment';
const VideoTableModal = ({ visible, closeModal, videoList, selectedDate }) => {
  const columns = [
    {
      key: 'thumbnail',
      title: 'Thumbnail',
      render: record => (
        <div>
          <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
            <img
              alt="video preview"
              src={record.thumbnail || require('assets/panamthumbnail.png')}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = require('assets/panamthumbnail.png');
              }}
              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
              draggable={false}
            />
          </div>
        </div>
      ),
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'title',
      title: 'Title',
      render: record => (
        <Button type="link" style={{ padding: '0', color: '#202541' }}>
          {record.title}
        </Button>
      ),
      sorter: (a, b) => a.title.length - b.title.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'activity',
      title: 'Activity',
      render: record => {
        return <span>{(record.activity && record.activity.name) || 'activity'}</span>;
      },
      sorter: (a, b) => a.activity.name.length - b.activity.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'trainer',
      title: 'Trainer',
      render: record => {
        return <span>{(record.trainer && record.trainer.name) || 'trainer'}</span>;
      },
      sorter: (a, b) => a.trainer.name.length - b.trainer.name.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'premiereAt',
      title: 'Premiere At',
      render: record => (record.premiereAt ? moment(record.premiereAt).format('Do MMMM, hh:mm a') : 'No Premiere'),
      sorter: (a, b) => new Date(b.premiereAt).getTime() - new Date(a.premiereAt).getTime(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      key: 'createdAt',
      title: 'Created At',
      render: record => moment(record.createdAt).format('Do MMMM, hh:mm a'),
      sorter: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record, index) => (
    //     <>
    //       <span style={{ marginRight: 10 }}>
    //         <Button type="primary" onClick={() => openEditVideoModal(record)}>
    //           Edit
    //         </Button>
    //       </span>
    //       <span>
    //         <Popconfirm title="Are you sure delete this video?" onConfirm={() => onDeleteVideo(record._id, index)} okText="Yes" cancelText="No">
    //           <Button type="danger">Delete</Button>
    //         </Popconfirm>
    //       </span>
    //     </>
    //   ),
    // },
  ];
  return (
    <>
      {selectedDate && videoList && (
        <Modal visible={visible} footer={null} onCancel={closeModal} title={selectedDate.format('Do MMM, YYYY')} width={1000}>
          <AntdTable pagination={false} rowKey={record => record._id} columns={columns} dataSource={videoList} />
        </Modal>
      )}
    </>
  );
};

export default VideoTableModal;
