import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Row,
  Col,
  Button,
  Icon,
  Modal,
  Form,
  Input,
  InputNumber,
  message,
  AutoComplete,
  Switch,
  Select,
  Radio,
  DatePicker,
  notification,
} from 'antd';
import { WrappedFormUtils, FormComponentProps } from 'antd/lib/form/Form';

import { UPDATE_VIDEO_MUTATION } from 'apollo/video/mutations/updateVideo';
import TextArea from 'antd/lib/input/TextArea';
import { TRAINERS_QUERY } from 'apollo/trainer/queries';
import { ACTIVITIES_QUERY } from 'apollo/activity/queries';
import LoadingSpinner from 'component/Loader';
import moment from 'moment';

interface VideoModalProps extends FormComponentProps {
  currentItem: any;
  handleCancel: any;
  handleOk: any;
  modalVisible: boolean;
}
const { Option } = Select;

const equipments = [
  { label: 'Body Weight', key: 'bodyWeight' },
  { label: 'Dumbbells', key: 'dumbbells' },
];

const EditVideoModalComponent: React.FC<VideoModalProps> = ({ form, currentItem, handleCancel, handleOk, modalVisible }) => {
  const [updateVideo] = useMutation(UPDATE_VIDEO_MUTATION);
  const [isLoading, setIsLoading] = useState(false);
  const [trainers, setTrainers] = useState<null | Array<Object>>(null);
  const [activities, setActivities] = useState<null | Array<Object>>(null);
  const [videoParam, setVideoParam] = useState({
    title: undefined,
    description: undefined,
    isLive: false,
    activity: { key: undefined, label: undefined },
    trainer: { key: undefined, label: undefined },
    intensity: '',
    equipments: [],
    tags: [],
    premium: false,
    premiere: false,
    allowComments: false,
    premiereAt: null as any,
  });

  useEffect(() => {
    if (currentItem) {
      setVideoParam(prev => ({
        ...prev,
        title: currentItem.title,
        description: currentItem.description,
        activity: { key: currentItem.activity._id, label: currentItem.activity.name },
        intensity: currentItem.intensity,
        equipments: currentItem.equipments,
        tags: currentItem.tags,
        trainer: { key: currentItem.trainer._id, label: currentItem.trainer.name },
        premiere: currentItem.premiere,
        premiereAt: currentItem.premiereAt ? moment(currentItem.premiereAt) : null,
        allowComments: currentItem.allowComments,
      }));
    }
  }, [currentItem]);

  useQuery(TRAINERS_QUERY, {
    onCompleted: data => {
      // console.log('trainers', data.trainers);
      setTrainers(data.trainers);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });
  useQuery(ACTIVITIES_QUERY, {
    onCompleted: data => {
      // console.log('activities', data);
      setActivities(data.activities);
    },
    onError: err => {
      const errorMessage = err.toString().replace('Error: GraphQL error:', '');
      message.error(errorMessage);
    },
  });

  const { getFieldDecorator } = form;

  const resetForm = () => {
    form.resetFields();
    setVideoParam({
      title: undefined,
      description: undefined,
      isLive: false,
      activity: { key: undefined, label: undefined },
      trainer: { key: undefined, label: undefined },
      intensity: '',
      equipments: [],
      tags: [],
      premium: false,
      premiere: false,
      premiereAt: null,
      allowComments: false,
    });
  };
  const renderEquipments = equipments.map(item => (
    <Option key={item.key} value={item.key}>
      {item.label}
    </Option>
  ));
  const onAllowCommentsChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, allowComments: val }));
  };
  const onIntensityChange = val => {
    // console.log('intensity', val);
    setVideoParam(prev => ({ ...prev, intensity: val }));
  };
  const handleEquipmentChange = val => {
    // console.log('equipment', val);
    setVideoParam(prev => ({ ...prev, equipments: val }));
  };

  const onPremiereChange = val => {
    // console.log('trainer', val);
    setVideoParam(prev => ({ ...prev, premiere: val }));
  };

  const onDateChange = val => {
    setVideoParam(prev => ({ ...prev, premiereAt: val }));
  };

  const handleCancelModal = () => {
    resetForm();
    handleCancel();
  };

  const handleSubmit = () => {
    form.validateFields((formErrors, values: any) => {
      if (formErrors) {
        return;
      }
      // return;
      let date = currentItem.premiereAt
        ? moment(currentItem.premiereAt).format('MMM DD, YYYY')
        : moment(currentItem.createdAt).format('MMM DD, YYYY');
      let title = values.title == undefined || values.title == '' ? `${values.activity.label} - ${date}` : values.title;
      setIsLoading(true);
      const params = {
        title: title,
        description: values.description,
        tags: values.tags,
        isLive: currentItem.isLive,
        activity: values.activity.key,
        trainer: values.trainer.key,
        intensity: values.intensity,
        equipments: values.equipments,
        premiere: videoParam.premiere,
        allowComments: videoParam.allowComments,
        premiereAt: videoParam.premiere ? values.premiereAt.toISOString() : null,
      };

      updateVideo({
        variables: {
          _id: currentItem._id,
          input: {
            ...params,
          },
        },
      })
        .then(updatedResult => {
          setIsLoading(false);
          notification.success({
            message: 'Video Updated',
            duration: 0,
            description: 'Video was updated successfully.',
          });
          resetForm();
          handleOk();
        })
        .catch(e => {
          setIsLoading(false);
          const errorMessage = e.toString().replace('Error: GraphQL error:', '');
          notification.error({
            message: 'Video Update Failed',
            duration: 0,
            description: errorMessage,
          });
        });
    });
  };
  return (
    <Modal
      title="Edit Video"
      footer={[
        <Button key="update" type="primary" loading={isLoading} onClick={handleSubmit}>
          Update
        </Button>,
      ]}
      visible={modalVisible}
      onOk={handleOk}
      onCancel={handleCancelModal}
    >
      {trainers && activities && currentItem ? (
        <Form layout="vertical">
          <Form.Item label="Thumbnail">
            <div style={{ position: 'relative', paddingBottom: '56.2%' }}>
              <img
                alt="video preview"
                src={currentItem.thumbnail || require('./../../assets/panamthumbnail.png')}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = require('./../../assets/panamthumbnail.png');
                }}
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
                draggable={false}
              />
            </div>
          </Form.Item>
          <Form.Item label="Title">
            {getFieldDecorator('title', {
              initialValue: videoParam.title,
            })(<Input placeholder="Enter Title" />)}
          </Form.Item>
          <Form.Item label="Description">
            {getFieldDecorator('description', {
              initialValue: videoParam.description,
            })(<TextArea rows={3} placeholder="Enter Description" />)}
          </Form.Item>
          <Form.Item label="Tags">
            {getFieldDecorator('tags', {
              initialValue: videoParam.tags,
            })(<Select mode="tags" style={{ width: '100%' }} placeholder="Enter Tags"></Select>)}
          </Form.Item>
          <Form.Item label="Trainer">
            {getFieldDecorator('trainer', {
              initialValue: videoParam.trainer,
              rules: [
                {
                  required: true,
                  message: 'Please input the trainer!',
                },
              ],
            })(
              <Select
                labelInValue
                showSearch
                placeholder="Select a trainer"
                optionFilterProp="children"
                // onChange={this.handleSelectChange}
                filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {trainers.length > 0 &&
                  trainers.map((item: any) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Activity">
            {getFieldDecorator('activity', {
              initialValue: videoParam.activity,
              rules: [
                {
                  required: true,
                  message: 'Please input the actvity!',
                },
              ],
            })(
              <Select
                labelInValue
                showSearch
                placeholder="Select a activity"
                optionFilterProp="children"
                // onChange={this.handleSelectChange}
                filterOption={(input, option: any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {activities.length > 0 &&
                  activities.map((item: any) => (
                    <Option key={item._id} value={item._id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Intensity">
            {getFieldDecorator('intensity', {
              initialValue: videoParam.intensity,
              rules: [
                {
                  required: true,
                  message: 'Please input the actvity!',
                },
              ],
            })(
              <Radio.Group onChange={onIntensityChange}>
                <Radio.Button value="low">Low</Radio.Button>
                <Radio.Button value="medium">Medium</Radio.Button>
                <Radio.Button value="high">High</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="Equiment Needed">
            {getFieldDecorator('equipments', {
              initialValue: videoParam.equipments,
            })(
              <Select mode="tags" style={{ width: '100%' }} placeholder="Select Equipment" onChange={handleEquipmentChange}>
                {renderEquipments}
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Allow Comments">
            {getFieldDecorator('allowComments', {
              initialValue: { checked: videoParam.allowComments },
              rules: [
                {
                  required: true,
                  message: 'Please input the title of Video!',
                },
              ],
            })(<Switch checked={videoParam.allowComments} onChange={onAllowCommentsChange} />)}
          </Form.Item>
          <Form.Item label="Premiere">
            {getFieldDecorator('premiere', {
              initialValue: { checked: videoParam.premiere },
              rules: [
                {
                  required: true,
                  message: 'Please input the title of Video!',
                },
              ],
            })(<Switch checked={videoParam.premiere} onChange={onPremiereChange} />)}
          </Form.Item>
          {videoParam.premiere && (
            <Form.Item label="Premiere At">
              {getFieldDecorator('premiereAt', {
                initialValue: videoParam.premiereAt,
                rules: [
                  {
                    required: true,
                    message: 'Please input premiere date!',
                  },
                ],
              })(<DatePicker showTime onChange={onDateChange} />)}
            </Form.Item>
          )}
        </Form>
      ) : (
        <LoadingSpinner />
      )}
    </Modal>
  );
};
const EditVideoModal = Form.create<VideoModalProps>()(EditVideoModalComponent);
export default EditVideoModal;
